.styles_contentWrapper__Cb6zS{-moz-column-gap:20px;column-gap:20px;display:grid;grid-template-columns:var(--default-grid);grid-auto-flow:column;padding:0 var(--default-grid-padding);max-width:var(--max-page-width);width:100%;}
.styles_field__TEDnw{--fg-color:var(--white);--accent-color:var(--purple);--placeholder-color:var(--grey);--border-color:var(--grey);display:flex;flex-direction:column;position:relative;transition:opacity .15s;width:100%;}
.styles_field__TEDnw:after{height:1px;background-color:var(--border-color);content:"";position:absolute;left:0;top:100%;transition:background .15s;width:100%;}
.styles_fieldLabel__k3gwi{color:#a793f6;transition:color .15s;}
.styles_fieldInput__U1hTD{background-color:transparent;color:var(--fg-color);padding:4px 0;width:100%;-webkit-appearance:none;}
.styles_fieldInput__U1hTD::placeholder{color:var(--placeholder-color);}
.styles_form__HFPN1{display:flex;flex-direction:column;}
.styles_formTitle___q9Ei{color:var(--fg-secondary);font-weight:100;margin-bottom:30px;max-width:25ch;}
.styles_formField__R0BFJ{margin-bottom:18px;}
.styles_footer___S6qT{background-image:var(--glass-mid-grey);display:flex;justify-content:center;width:100%;}
.styles_footerNav__SK3Q_{-moz-column-gap:20px;column-gap:20px;display:grid;grid-column:1/9;grid-template-columns:repeat(8,1fr);padding:25px 0;row-gap:28px;}
.styles_navItemGroup__J5K75{grid-column:span 2;}
.styles_navItemGroupTitle__LZ3dY{--font-weight:500;color:var(--grey);display:block;margin-bottom:12px;}
.styles_navItem__LJCKt{color:var(--fg-secondary);margin-bottom:8px;}
.styles_footerForm__u791a{grid-column:9/13;justify-self:flex-end;max-width:295px; width:100%;}
@media screen and (max-width:800px){
.styles_footerForm__u791a{grid-column:1/9;grid-row:1/2;justify-self:flex-start;max-width:500px;padding:34px 0;}
.styles_footerNav__SK3Q_{grid-row:2/3;grid-template-columns:repeat(6,1fr);}
.styles_navItemGroup__J5K75{grid-column:span 3;}
}

/*  */
input.specific-input {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    padding: 0;
    line-height: inherit;
    color: inherit;
    background-color: transparent;
    border: none; /* Remove the default border */
    border-bottom: 1px solid #797b8c; /* Add a bottom border */
    outline: none; /* Remove the default focus outline */
  }
  
  input.specific-input::placeholder {
    opacity: 1;
    color: #9ca3af;
  }
  
  *,
  :after,
  :before {
    box-sizing: border-box;
    border: 0 solid;
  }
  
  .styles_fieldInput__U1hTD {
    background-color: transparent;
    color: var(--fg-color);
    padding: 4px 0;
    width: 100%;
    -webkit-appearance: none;
  }
  
  .styles_fieldInput__U1hTD::placeholder {
    color: var(--placeholder-color);
  }
  
  .bg-transparent {
    background-color: transparent;
  }