.layout-base {
  background-image: url(https://my.ine.com/img/dashboard_bg.fcc8d0cd.jpg);
  /* repeating-linear-gradient(50deg, #0000004d, #201820d9 100px); */
}

.hexagon-container {
  width: 100%;
  height: 100vh;
  clip-path: polygon(0 0, 56% 0, 38% 100%, 0% 100%);
  position: relative;
}

.hexagon-container2 {
  margin-top: -100vh;
  width: 100%;
  height: 100vh;
  clip-path: polygon(100% 0, 56% 0, 38% 100%, 100% 100%);
  position: relative;
}

/* Add these styles for the hover effect */
.hexagon-container:hover::before,
.hexagon-container2:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(10, 10, 10, 0.2); /* Adjust the background color and opacity as needed */
  z-index: 1;
  border: 2px solid #444444;
}

@media screen and (max-width: 700px) {
  .hexagon-container,
  .hexagon-container2 {
    clip-path: none; /* Remove clip-path for smaller screens */
    width: 100%;
    height: 50vh; /* Adjust height as needed for smaller screens */
    margin-top: 0; /* Remove margin-top for hexagon-container2 */
    position: relative;
  }

  .hexagon-container2 {
    height: 50vh; /* Adjust height as needed for smaller screens */
  }
}
.left-inner {
  width: 40%;
  height: 100vh;
  display: flex;
  align-items: left;
  justify-content: center;
  padding: 30px;
  flex-direction: column;
  left: 3%;
}
.right-inner {
  left: 60%;
  width: 40%;
  height: 100vh;
  display: flex;
  align-items: left;
  justify-content: center;
  padding: 30px;
  flex-direction: column;
}

@media screen and (max-width: 700px) {
  .left-inner {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: left;
    justify-content: center;
    padding: 30px;
    flex-direction: column;
    left: 0%;
  }
  .right-inner {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: left;
    justify-content: center;
    padding: 30px;
    flex-direction: column;
    left: 0%;
  }
}

.custom-heading {
  font-family: "Inter", sans-serif;
  font-weight: bolder;
  font-size: 6vw;
  color: #a3a3a3;
  line-height: 0.9;
  letter-spacing: -2px;
  margin-bottom: 37px;
}

.custom-heading span {
  color: #787775c2;
}
.custom-container-2 {
  width: 85%;
  padding: 10px;
}

.custom-heading-2 {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1em;
  color: #929394;
}

.custom-paragraph-2 {
  font-size: 14px;
  line-height: 20px;
  color: #7d7d7d;
  font-weight: normal;
}
/* button */
.base-btn-container {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 15px;
}
.fancy {
  background-color: transparent;
  /* border: 2px solid #5c5c5a; */
  border-radius: 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  float: right;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 1.25em 2em;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  user-select: none;
  font-size: 13px;
}

.fancy::before {
  content: " ";
  width: 1.5625rem;
  height: 2px;
  background: black;
  top: 50%;
  left: 1.5em;
  position: absolute;
  transform: translateY(-50%);
  transform-origin: center;
  transition: background 0.3s linear, width 0.3s linear;
}

.fancy .text {
  font-size: 1.125em;
  line-height: 1.33333em;
  padding-left: 2em;
  display: block;
  text-align: left;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: #8a8a8a;
}

.fancy .top-key {
  height: 2px;
  width: 1.5625rem;
  top: -2px;
  left: 0.625rem;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, left 0.3s ease-out;
}

.fancy .bottom-key-1 {
  height: 2px;
  width: 1.5625rem;
  right: 1.875rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy .bottom-key-2 {
  height: 2px;
  width: 0.625rem;
  right: 0.625rem;
  bottom: -2px;
  position: absolute;
  background: #e8e8e8;
  transition: width 0.5s ease-out, right 0.3s ease-out;
}

.fancy:hover {
  color: white;
  background: rgb(24, 22, 23);
}

.fancy:hover::before {
  width: 0.9375rem;
  background: white;
}

.fancy:hover .text {
  color: white;
  padding-left: 1.5em;
}

.fancy:hover .top-key {
  left: -2px;
  width: 0px;
}

.fancy:hover .bottom-key-1,
.fancy:hover .bottom-key-2 {
  right: 0;
  width: 0;
}
