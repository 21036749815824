/* * {
  font-family: "CircularStd-Book", "Helvetica", "Arial", sans-serif !important;
} */

.sample-video {
  width: 100%;
}

.overview__video {
  width: 100%;
}
@media (min-width: 1280px) {
  .overview__video {
    width: 60%;
  }

}

@media screen and (max-width: 1280px) {
  .overview__course-info-wrapper {
    width: 100%;
  }
}
  

.overview-gif {
  width: 100%;
  height: 470px;
}

.inactive{
    filter: blur(5px);
}

.inactive_overlay {
    color: #efeeee96;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
 .unstarted {
  position: relative;
}
.blurStateMessage{
    font-size: inherit;
    margin-left: 12px;
}
/* 
.unstarted::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(5px); /* Apply the blur effect to the overlay */
    /* z-index: 1;
  }
  
  .unstarted::before {
    content: "Coming soon";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #fff;
    z-index: 2;
  } */
.level__title__label{
  display: flex;
  margin-top: 5px;
}

.level__title__label a {
  border: 1px solid #ffffff63;
  color: inherit;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
  margin-left: 5px;
  margin-top: 2px;
}

.level__title__label a:hover {
  background-color: #ffffffbd;
  color: #000;
}
.path_bg_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-image: url('/src/assets/images/background.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.padjust{
  padding-bottom: 0px;
}

/* breadcrumb */
.breadcrumb_list {
  padding: 15px 0;
  margin-top: 50px;
}

.breadcrumb_list li {
  list-style-type: none;
  display: inline-flex;
  /* align-items: center; */
  margin: 0 6px;
  padding: 4px 11px 4px 8px;
}


.breadcrumb_list .bread-icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
  fill: currentColor;
}

.breadcrumb_list a {
  color: #b3b3b3;
  font-size: 14px;
  text-decoration: none;
}

.breadcrumb_list a:visited,
.breadcrumb_list a:hover {
  color: #e6e6e6;
}

.li-4:after {
  content: '\00bb';
  padding-left: 20px;
  /* padding-top: 3px; */
  color: #b3b3b3;
}
.bread-icon{
  color: #fff;
}

.active-line{
  border-bottom: 1px solid #c0c0c047;
  /* padding: 4px 11px 4px 8px; */
  /* border-radius: 7px; */
}

.bread-icon-hidden{
  visibility: hidden;
}
/* hightlight when li is hovred */


/* .breadcrumb_list li:hover{


} */
