.cc-window {
    opacity: 1;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
}

.cc-window.cc-invisible {
    opacity: 0;
}

.cc-window {
    position: fixed;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    font-family: Helvetica, Calibri, Arial, sans-serif;
    font-size: 16px;
    line-height: 1.5em;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    z-index: 9999;
}

.cc-window.cc-floating {
    padding: 2em;
    max-width: 24em;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.cc-btn,
.cc-link {
    cursor: pointer;
}

.cc-link {
    opacity: .8;
    display: inline-block;
    padding: .2em;
    text-decoration: underline;
}

.cc-link:hover {
    opacity: 1;
}

.cc-link:active,
.cc-link:visited {
    color: initial;
}

.cc-btn {
    display: block;
    padding: .4em .8em;
    font-size: .9em;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
}

.cc-right {
    right: 1em;
}

.cc-bottom {
    bottom: 1em;
}

.cc-floating .cc-message {
    display: block;
    margin-bottom: 1em;
}

.cc-window.cc-floating .cc-compliance {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
}

.cc-compliance {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-line-pack: justify;
    align-content: space-between;
}

.cc-floating .cc-compliance>.cc-btn {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

@media print {
    .cc-window {
        display: none;
    }
}

@media screen and (max-width:900px) {
    .cc-btn {
        white-space: normal;
    }
}

@media screen and (max-width:414px) and (orientation:portrait),
screen and (max-width:736px) and (orientation:landscape) {
    .cc-window.cc-bottom {
        bottom: 0;
    }

    .cc-window.cc-floating,
    .cc-window.cc-right {
        left: 0;
        right: 0;
    }

    .cc-window.cc-floating {
        max-width: none;
    }

    .cc-window .cc-message {
        margin-bottom: 1em;
    }
}

.cc-floating.cc-theme-classic {
    padding: 1.2em;
    border-radius: 5px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
    text-align: center;
    display: inline;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
}

.cc-theme-classic .cc-btn {
    border-radius: 5px;
}

.cc-theme-classic .cc-btn:last-child {
    min-width: 140px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
    display: inline-block;
}

:root {
    --vs-colors--lightest: rgba(60, 60, 60, 0.26);
    --vs-colors--light: rgba(60, 60, 60, 0.5);
    --vs-colors--dark: #333;
    --vs-colors--darkest: rgba(0, 0, 0, 0.15);
    --vs-search-input-color: inherit;
    --vs-search-input-placeholder-color: inherit;
    --vs-font-size: 1rem;
    --vs-line-height: 1.4;
    --vs-state-disabled-bg: #f8f8f8;
    --vs-state-disabled-color: var(--vs-colors--light);
    --vs-state-disabled-controls-color: var(--vs-colors--light);
    --vs-state-disabled-cursor: not-allowed;
    --vs-border-color: var(--vs-colors--lightest);
    --vs-border-width: 1px;
    --vs-border-style: solid;
    --vs-border-radius: 4px;
    --vs-actions-padding: 4px 6px 0 3px;
    --vs-controls-color: var(--vs-colors--light);
    --vs-controls-size: 1;
    --vs-controls--deselect-text-shadow: 0 1px 0 #fff;
    --vs-selected-bg: #f0f0f0;
    --vs-selected-color: var(--vs-colors--dark);
    --vs-selected-border-color: var(--vs-border-color);
    --vs-selected-border-style: var(--vs-border-style);
    --vs-selected-border-width: var(--vs-border-width);
    --vs-dropdown-bg: #fff;
    --vs-dropdown-color: inherit;
    --vs-dropdown-z-index: 1000;
    --vs-dropdown-min-width: 160px;
    --vs-dropdown-max-height: 350px;
    --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
    --vs-dropdown-option-bg: #000;
    --vs-dropdown-option-color: var(--vs-dropdown-color);
    --vs-dropdown-option-padding: 3px 20px;
    --vs-dropdown-option--active-bg: #5897fb;
    --vs-dropdown-option--active-color: #fff;
    --vs-dropdown-option--deselect-bg: #fb5858;
    --vs-dropdown-option--deselect-color: #fff;
    --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
    --vs-transition-duration: 150ms;
}

:root {
    --vs-transition-timing-function: cubic-bezier(1, 0.5, 0.8, 1);
    --vs-transition-duration: 0.15s;
}

:root {
    --vs-disabled-bg: var(--vs-state-disabled-bg);
    --vs-disabled-color: var(--vs-state-disabled-color);
    --vs-disabled-cursor: var(--vs-state-disabled-cursor);
}

.footer {
    /* font-family: var(--font-medium); */
    font-style: normal;
    font-weight: 400;
    color: var(--white);
}

.footer__bottom,
.footer__top {
    max-width: 1440px;
    margin: auto;
    padding: 50px 20px;
}

.footer__bottom-wrapper,
.footer__top-wrapper {
    background: var(--glass-mid-gray);
}

.footer__top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media (min-width:1024px) {
    .footer__top {
        grid-template-columns: repeat(5, 1fr);
        padding: 50px 24px;
    }
}

@media (min-width:1300px) {
    .footer__top {
        padding: 50px 100px;
        grid-template-columns: repeat(6, 1fr);
    }
}

.footer__bottom {
    padding-bottom: 40px;
}

@media (min-width:1024px) {
    .footer__bottom {
        padding: 40px 24px 24px;
    }
}

@media (min-width:1300px) {
    .footer__bottom {
        display: grid;
        grid-template-columns: 57% 23% 20%;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 40px 100px 24px;
    }
}

.footer__links-list {
    margin-bottom: 20px;
}

.footer__links-list:first-of-type {
    grid-column: 1/2;
    grid-row: 2/4;
}

@media (min-width:1024px) {
    .footer__links-list:first-of-type {
        grid-column: 1/2;
        grid-row: 1;
    }
}

@media (min-width:1300px) {
    .footer__links-list:first-of-type {
        grid-column: 1/2;
        grid-row: 1;
    }
}

.footer__links-list:nth-of-type(2) {
    grid-column: 2/3;
    grid-row: 2/3;
}

@media (min-width:1024px) {
    .footer__links-list:nth-of-type(2) {
        grid-column: 2/3;
        grid-row: 1;
    }
}

@media (min-width:1300px) {
    .footer__links-list:nth-of-type(2) {
        grid-column: 2/3;
        grid-row: 1;
    }
}

.footer__links-list:nth-of-type(3) {
    grid-column: 2/3;
    grid-row: 3/4;
}

@media (min-width:1024px) {
    .footer__links-list:nth-of-type(3) {
        grid-column: 3/4;
        grid-row: 1;
    }
}

@media (min-width:1300px) {
    .footer__links-list:nth-of-type(3) {
        grid-column: 3/4;
        grid-row: 1;
    }
}

.footer__links-list-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
    color: var(--gray-storm);
}

.footer__links-list-item {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 0;
}

.footer__links-list-item a {
    -webkit-transition: padding .15s ease;
    transition: padding .15s ease;
    color: var(--white);
}

.footer__links-list-item a:hover {
    padding-left: 5px;
}

.footer__links-list-item a:visited {
    color: var(--white);
}

.footer__community-section {
    grid-column: 1/7;
    grid-row: 1/2;
}

@media (min-width:1024px) {
    .footer__community-section {
        grid-column: 4/6;
        grid-row: 1;
    }
}

@media (min-width:1300px) {
    .footer__community-section {
        grid-column: 5/7;
        grid-row: 1;
    }
}

.footer__community-title {
    font-size: 12px;
    line-height: 14px;
    color: var(--chetwode-blue);
    margin-bottom: 15px;
}

.footer__community-text {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 30px;
}

@media (min-width:1024px) {
    .footer__community-text {
        width: 95%;
    }
}

.footer__community-button-cta {
    height: 36px;
    font-size: 14px;
    line-height: 20px;
    color: var(--charcoal);
    padding: 8px 16px;
    margin-bottom: 30px;
}

.footer__all-rights-reserved {
    font-size: 12px;
    line-height: 14px;
}

@media (min-width:1024px) {
    .footer__all-rights-reserved {
        text-align: center;
    }
}

.footer__links-policies {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 45px 0;
}

@media (min-width:1300px) {
    .footer__links-policies {
        padding: unset;
        grid-template-columns: repeat(2, 140px);
    }
}

.footer__links-policies-item {
    font-size: 14px;
    line-height: 20px;
    padding: 0 30px;
}

@media (min-width:1300px) {
    .footer__links-policies-item {
        padding: unset;
        text-align: center;
    }
}

.footer__links-policies-item:first-of-type {
    width: 100%;
    border-right: 1px var(--gray-storm) solid;
    text-align: right;
    padding-right: 30px;
}

@media (min-width:1300px) {
    .footer__links-policies-item:first-of-type {
        width: unset;
        border-right: unset;
        padding: unset;
        text-align: center;
    }
}

.footer__links-policies-item:link,
.footer__links-policies-item:visited {
    color: var(--white);
}

.footer__links-policies-item:hover {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
}

.footer__links-social {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 0 67.5px;
    max-width: 380px;
    margin: auto;
}

@media (min-width:1024px) {
    .footer__links-social {
        padding: unset;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        grid-template-columns: repeat(4, 52px) 42px;
    }
}

@media (min-width:1300px) {
    .footer__links-social {
        padding: unset;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        grid-template-columns: repeat(4, 52px) 42px;
    }
}

.footer__links-social-item {
    justify-self: center;
    width: 32px;
    height: 32px;
    background: var(--glass-light-gray);
    border-radius: 50%;
    -webkit-transition: background .15s ease;
    transition: background .15s ease;
}

.footer__links-social-item svg path {
    -webkit-transition: fill .15s ease;
    transition: fill .15s ease;
}

@media (min-width:1300px) {
    .footer__links-social-item {
        margin: 0 10px;
    }
}

@media (min-width:1300px) {
    .footer__links-social-item:last-child {
        margin: unset;
        margin-left: 10px;
    }
}

.footer__links-social-item:hover {
    background: var(--white);
}

.footer__links-social-item:hover svg path {
    fill: var(--black);
}

.footer__links-social-item--youtube {
    margin: 10px 8px;
}

.footer__links-social-item--instagram {
    margin: 7px;
}

.footer__links-social-item--facebook,
.footer__links-social-item--linkedin,
.footer__links-social-item--twitter {
    margin: 8px;
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

main {
    display: block;
}

h1 {
    font-size: 1.5rem;
    margin: .67em 0;
}

a {
    background-color: transparent;
}

img {
    border-style: none;
}

button,
input,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

button,
input {
    overflow: visible;
}

button {
    text-transform: none;
}

[type=submit],
button {
    -webkit-appearance: button;
}

textarea {
    overflow: auto;
}

:root {
    --orange: #ef7d22;
    --gray: grey;
    --bright-gray: #aaa;
    --light-gray: #262626;
    --light-gray-darker: #57575c;
    --light-elevation-200: rgba(55, 65, 81, 0.0784313725490196);
    --dark-gray: #151515;
    --darker-gray: #222;
    --business-gray: #aeaeb2;
    --gallery: #eee;
    --shark: #2a282a;
    --mulled-wine-50: rgba(75, 72, 103, 0.5019607843137255);
    --primary-ine-gradient: linear-gradient(92.23deg, #ef7d22 3.65%, #ef741d 25.47%, #ee5a10 62.46%, #ee3b00 98.5%);
    --glass-mid-gray: linear-gradient(104.99deg, rgba(62, 63, 73, 0.7) -13.91%, rgba(62, 63, 73, 0) 100.48%);
    --glass-mid-gray-light: linear-gradient(112.2deg, rgba(187, 188, 199, 0.4) -46.39%, rgba(187, 188, 199, 0) 112.38%);
    --glass-solid-mid-gray: linear-gradient(89.99deg, #777 0.5%, #2e2e2e 97.14%);
    --glass-light-gray: linear-gradient(112.2deg, rgba(187, 188, 199, 0.7) -46.39%, rgba(187, 188, 199, 0) 112.38%);
    --glass-extra-light: linear-gradient(112.2deg, rgba(187, 188, 199, 0.4) -46.39%, rgba(187, 188, 199, 0) 112.38%);
    --glass-dark: linear-gradient(111.02deg, rgba(18, 18, 18, 0.7) 3.86%, rgba(18, 18, 18, 0) 107.96%);
    --glass-purple: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    --glass-red: linear-gradient(180deg, #c31432 3.12%, rgba(195, 20, 50, 0));
    --glass-pink-violet: linear-gradient(96.51deg, #cf3ad2 7.05%, #5936e4 93.87%);
    --highlight-gray: #2e2e2e;
    --lighter-gray: #717171;
    --white: #fff;
    --white-10: hsla(0, 0%, 100%, 0.10196078431372549);
    --white-20: hsla(0, 0%, 100%, 0.2);
    --white-25: hsla(0, 0%, 100%, 0.25098039215686274);
    --flat-black: #333;
    --black: #000;
    --black-50: rgba(0, 0, 0, 0.5019607843137255);
    --black-footer: #010101;
    --little-black: #292929;
    --red: red;
    --error-background: 20, 26, 32, 0.9;
    --notification-box-shadow: 0px 0px 16px rgba(5, 73, 152, 0.38823529411764707);
    --green: #00dc9b;
    --yellow: #eeecb4;
    --dark-yellow: #edbd02;
    --border-gray: #eaeaea;
    --border-dark-gray: #acacac;
    --purple: #b53dc5;
    --secondary-promo-purple: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    --blue-gem-gradient: linear-gradient(358.73deg, rgba(61, 36, 165, 0) 13.2%, #3d24a5 99.82%);
    --blue: #2c6ed0;
    --lochmara: #0073cb;
    --menu-background: #1d1d1d;
    --menu-btn: #333;
    --menu-detail: #989898;
    --menu-subcategory: #777;
    --menu-gradient: linear-gradient(180deg, #111, rgba(17, 17, 17, 0));
    --manage-form-input: #272727;
    --manage-form-placeholder: #aaa;
    --lab-status-green: #22d895;
    --lab-status-yellow: #ffde29;
    --lab-status-red: #fd352e;
    --tango: #ef7d23;
    --highlighter: #ffc504;
    --highlighted: #f83333;
    --metric-gradient: radial-gradient(closest-side at 50% 50%, #215bc7 0%, rgba(33, 19, 177, 0) 100%) 0% 0% no-repeat padding-box;
    --cert-gray: #252525;
    --charcoal: #1e1e1e;
    --free-alt-bg: #f7f8fa;
    --off-white: #f7f8f9;
    --error: red;
    --detail: #acb2bf;
    --input-background: #111720;
    --input-border: #29285a;
    --cart-background: 20, 26, 32, 0.9;
    --cod-gray: #1c1c1c;
    --dove-gray: #666;
    --silver-gray: #8b8b8b;
    --emperor-gray: #555;
    --payment-border: #202245;
    --payment-border-selected: #3f4388;
    --payment-box-shadow: 0px 0px 16px rgba(5, 73, 152, 0.38823529411764707);
    --checkbox-border: #30336a;
    --tundora-gray: #444;
    --silver-chalice: #b1b1b1;
    --light-blue-gray: #afb0bf;
    --perfect-gray: #888;
    --athens-gray: #e9e9ed;
    --positive-light: #70ffd4;
    --cornflower-blue: #88a9ff;
    --silver-chalice-2: #aaa;
    --mid-gray: #bbbcc7;
    --mid-gray-dark: #797b8c;
    --mid-gray-dark-25: rgba(121, 123, 140, 0.25098039215686274);
    --dark-brighter: #111;
    --aubergine: #221f20;
    --red-gradient: linear-gradient(90deg, #db4133 8310%, #de5936 11665.11%, #e27339 15935.25%, #e37c3a 18477%);
    --gray-lavender: #717071;
    --gray-nine: #999;
    --gray-storm: #797b8c;
    --chetwode-blue: #a793f6;
    --chetwode-blue-50: rgba(167, 147, 246, 0.5019607843137255);
    --orange-gradient: linear-gradient(180.18deg, #db4133 -5.52%, #de5936 36.62%, #e27339 90.25%, #e37c3a 122.17%);
    --oranger-gradient: linear-gradient(92.23deg, #ef7d22 3.65%, #ef741d 25.47%, #ee5a10 62.46%, #ee3b00 98.5%);
    --orangest-horizontal-gradient: linear-gradient(90deg, #db4133, #de5935 33%, #e17238 75%, #e37c3a);
    --orangest-vertical-gradient: linear-gradient(180.18deg, #db4133 -5.52%, #de5935 36.62%, #e17238 90.25%, #e37c3a 122.17%);
    --purple-gradient: linear-gradient(96.51deg, #863ad2 7.05%, #5936e4 93.87%);
    --purple-horizontal-gradient: linear-gradient(270deg, #803995 0.01%, #ea2426 100.01%);
    --purple-vertical-gradient: linear-gradient(0deg, #803995 0.01%, #ea2426 100.01%);
    --purple-pale: #c9bbff;
    --dark-blue: #190da0;
    --caribbean-green: #49e0b3;
    --carnation: #ff5e5e;
    --butterfly-bush: #5b4b9b;
    --shark-gray: #2a2a2f;
    --pale-yellow: #fffec9;
    --light-yellow: #fce57f;
    --performance-light: #f8b369;
    --concrete: #f2f2f2;
    --melrose: #c4b7f9;
    --white-light-box-shadow: 0px 4px 40px hsla(0, 0%, 100%, 0.4);
    --white-medium-box-shadow: 0px 0px 19px hsla(0, 0%, 100%, 0.26);
    --gray-light-box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
    --gray-light-box-shadow-filter: drop-shadow(0px 1px 2px rgba(55, 65, 81, 0.08));
    --carnation-box-shadow: 0px 0px 0px 1px rgba(255, 94, 94, 0.3), inset 0px 0px 0px 1px #ff5e5e;
    --purple-dark-box-shadow: 0px 0px 0px 1px #5b4b9b, inset 0px 0px 0px 1px #a793f6;
    --purple-light: #a793f6;
    --purple-light-box-shadow: 0px 0px 10px rgba(167, 147, 246, 0.5);
    --positive-light-shadow: 0px 0px 10px var(--positive-light);
    --violet-light: #d7cdff;
    --fog: #d3c9fa;
    --larea-networking: #fad740;
    --larea-cloud: #88a9ff;
    --larea-data-science: #66c19a;
    --larea-cyber-security: #bb85ff;
    --larea-it-essentials: #f647c0;
    --larea-development: #d73f47;
    --font-book: "CircularStd-Book";
    --font-bold: "CircularStd-Bold";
    --font-medium: "CircularStd-Medium";
    --font-roboto: "RobotoMono-Regular";
}

* {
    text-decoration: none;
    outline: none !important;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

body,
html {
    height: 100%;
}

*,
:after,
:before {
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
}

body {
    background-color: var(--dark-gray);
    font-family: var(--font-book);
    min-width: 100px;
    min-height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.5;
    font-size: 14px;
    overflow-x: hidden;
    width: 100%;
    direction: ltr;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    scroll-behavior: smooth;
}

p {
    margin: 0 0 16px;
}

button,
input {
    overflow: visible;
}

button,
input,
textarea {
    font: inherit;
    margin: 0;
}

.btn {
    --btn-height: 48px;
    --btn-side-pad: 24px;
    --btn-border-radius: 8px;
    --btn-bg-color: var(--white, #fff);
    --btn-active-bg-color: var(--athens-gray);
    --btn-font-size: 1rem;
    --btn-text-color: var(--charcoal, #0c0c0c);
    --btn-border-color: var(--emperor-gray, #555);
    --btn-hover-border-color: var(--white, #fff);
    --btn-secondary-text-color: var(--white, #fff);
    --btn-secondary-active-text-color: var(--charcoal, #0c0c0c);
    --btn-icon-size: 24px;
    --btn-disabled-opacity: 0.2;
    --btn-promo-text-color: var(--glass-purple);
    --btn-promo-active-text-color: var(--charcoal, #0c0c0c);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-column-gap: 10px;
    -moz-column-gap: 10px;
    column-gap: 10px;
    height: var(--btn-height, 48px);
    padding: 0 var(--btn-side-pad, 24px);
    border-radius: var(--btn-border-radius, 8px);
    background-color: var(--btn-bg-color, var(--white, #fff));
    border: none;
    font-size: var(--btn-font-size, 1rem);
    color: var(--btn-text-color, var(--charcoal, #0c0c0c));
    -webkit-transition: all .3s;
    transition: all .3s;
    will-change: background-color, border-color;
    cursor: pointer;
    white-space: nowrap;
}

.btn svg {
    width: var(--btn-icon-size, 24px);
    height: var(--btn-icon-size, 24px);
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.btn.btn--primary {
    --btn-box-shadow-opacity: 0.4;
}

.btn.btn--primary:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    opacity: 0;
    will-change: opacity;
    -webkit-transition: opacity .3s, -webkit-box-shadow .3s;
    transition: opacity .3s, -webkit-box-shadow .3s;
    transition: opacity .3s, box-shadow .3s;
    transition: opacity .3s, box-shadow .3s, -webkit-box-shadow .3s;
    -webkit-box-shadow: 0 4px 40px rgba(255, 255, 255, var(--btn-box-shadow-opacity));
    box-shadow: 0 4px 40px rgba(255, 255, 255, var(--btn-box-shadow-opacity));
    pointer-events: none;
}

.btn.btn--primary:active:before,
.btn.btn--primary:hover:before {
    opacity: 1;
}

.btn.btn--primary:active {
    --btn-box-shadow-opacity: 0.13;
    --btn-bg-color: var(--btn-active-bg-color) !important;
}

.btn.btn--primary:disabled,
.btn.btn--primary[disabled] {
    --btn-text-color: var(--dove-gray, #666);
}

.btn.btn--secondary {
    --btn-bg-color: transparent;
    --btn-text-color: var(--btn-secondary-text-color);
    border: 1px solid var(--btn-border-color);
}

.btn.btn--secondary:active,
.btn.btn--secondary:hover {
    --btn-border-color: var(--btn-hover-border-color) !important;
}

.btn.btn--secondary:active {
    --btn-bg-color: var(--btn-border-color) !important;
    --btn-text-color: var(--btn-secondary-active-text-color) !important;
}

.btn.btn--tertiary {
    --btn-bg-color: transparent;
    --btn-text-color: var(--btn-secondary-text-color);
}

.btn.btn--tertiary:hover {
    --btn-bg-color: hsla(0, 0%, 100%, 0.1);
}

.btn.btn--tertiary:active {
    --btn-bg-color: hsla(0, 0%, 100%, 0.2);
}

.btn:disabled,
.btn[disabled] {
    opacity: var(--btn-disabled-opacity);
    pointer-events: none;
}

.ine-form__logo {
    position: absolute;
    width: 105px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: 50%;
}

@media (min-width:768px) {
    .ine-form__logo {
        width: 135px;
    }
}

@media (min-width:1024px) {
    .ine-form__logo {
        -webkit-transform: none;
        transform: none;
        left: 40px;
        top: 40px;
        position: fixed;
    }
}

.ine-form__form {
    width: 100%;
    height: 100%;
    text-align: center;
    min-height: 80vh;
}

@media (min-width:1024px) {
    .ine-form__form {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        min-height: 100vh;
    }
}

.ine-form__title-wrapper {
    color: var(--white);
    width: 100%;
    text-align: center;
    padding: 20px 0;
}

@media (min-width:1024px) {
    .ine-form__title-wrapper {
        padding: 0;
    }
}

.ine-form__title {
    font-size: 1.5rem;
    line-height: 32px;
    font-weight: 450;
    padding-bottom: 10px;
    margin: 0 auto;
    width: 100%;
}

.ine-form__tagline {
    font-size: 1.125rem;
    color: var(--light-blue-gray);
    margin-bottom: 50px;
    width: 100%;
}

.ine-form__submit-btn-wrapper {
    margin: 15px auto;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (min-width:1024px) {
    .ine-form__submit-btn-wrapper {
        margin: 10px auto 15px auto;
    }
}

.ine-form__additional-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0;
    padding: 20px 0 0 0;
    width: 100%;
    margin: 0 auto;
    left: 40px;
    bottom: 40px;
}

@media (min-width:1024px) {
    .ine-form__additional-links {
        position: fixed;
        width: calc(50% - 60px);
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }
}

.ine-form__additional-link {
    text-decoration: none;
    font-size: .875rem;
    color: var(--white);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-right: 40px;
}

.ine-form__additional-link:last-child {
    margin: 0;
}

@media (min-width:768px) {
    .ine-form__additional-link {
        margin-right: 50px;
    }
}

.ine-form__link--signup {
    margin-left: 5px;
}

.ine-form__link-wrapper--lead {
    font-size: .875rem;
    color: var(--white);
    margin: 0;
    padding: 0;
}

.ine-input {
    background: var(--darker-gray);
    border: 1px solid var(--emperor-gray);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: var(--gray-light-box-shadow);
    box-shadow: var(--gray-light-box-shadow);
    border-radius: 4px;
}

.ine-input {
    font-size: .75rem;
    width: 100%;
    height: 46px;
    color: var(--gray-nine);
    padding: 11px 40px 11px 15px;
}

.ine-input:focus {
    color: var(--light-blue-gray);
    border: 1px solid var(--flat-black);
    -webkit-box-shadow: var(--purple-dark-box-shadow);
    box-shadow: var(--purple-dark-box-shadow);
}

.ine-input:hover {
    color: var(--light-blue-gray) !important;
    border: 1px solid var(--perfect-gray) !important;
}

.ine-input:disabled {
    background: var(--darker-gray);
    border: 1px solid --flat-black;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: var(--gray-light-box-shadow);
    box-shadow: var(--gray-light-box-shadow);
    color: var(--tundora-gray);
}

.auth-form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.auth-form__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: var(--secondary-promo-purple);
    width: 100%;
}

@media (min-width:1024px) {
    .auth-form__container {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

.auth-form__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
}

@media (min-width:1024px) {
    .auth-form__container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    }
}

.auth-form__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 30px;
    background: var(--glass-mid-gray) #222;
    min-height: calc(100vh - 137px);
    border-radius: 35px 35px 0 0;
}

@media (min-width:768px) {
    .auth-form__wrapper {
        min-height: calc(100vh - 180px);
    }
}

@media (min-width:1024px) {
    .auth-form__wrapper {
        padding: 110px 60px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 50%;
        min-height: 100vh;
        border-radius: 0;
    }
}

.auth-form__wrapper--centered {
    width: 100%;
}

@media (min-width:1300px) {
    .auth-form__wrapper--centered {
        min-height: 685px;
        margin-top: 10px;
    }
}

@media (min-width:1024px) {
    .auth-form__wrapper {
        padding: 60px;
    }
}

.auth-form__form-group,
.auth-form__form-wrapper {
    position: relative;
    width: 100%;
}

.auth-form__form-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.auth-form__input-wrapper {
    position: relative;
    width: 100%;
}


.auth-form__input-wrapper:first-child input {
    margin: 0;
}

.auth-form__input-wrapper input {
    margin: 10px 0 0;
}
.auth-form__input-wrapper:last-child input {
    margin: 10px 0 0;
}

.auth-form__form {
    max-width: 480px;
    min-height: 0;
    margin: auto;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.auth-form__forms {
    margin: 0 auto;
    position: relative;
    max-width: 600px;
}

@media (min-width:768px) {
    .auth-form__forms {
        margin: 40px auto 0;
    }
}

@media (min-width:1024px) {
    .auth-form__forms {
        margin: 0;
    }
}

@media (min-width:1024px) {
    .auth-form__forms-login {
        margin: 50px auto 0;
    }
}

.auth-form__title-wrapper {
    padding: 0;
}

@media (min-width:1024px) {
    .auth-form__title-wrapper {
        padding: 20px 0 0;
    }
}

.auth-form__form-control {
    width: 100%;
    margin: 0;
}

.auth-form__oauth {
    position: relative;
    width: 100%;
    margin: 30px 0 20px;
    padding: 20px 0 0 0;
    border-top: 1px solid var(--tundora-gray);
}

@media (min-width:768px) {
    .auth-form__oauth {
        margin: 50px 0 50px 0;
    }
}

.auth-form__oauth-separator {
    position: absolute;
    font-size: .75rem;
    color: var(--white);
    top: -19px;
    width: 38px;
    height: 38px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-radius: 60px;
    background: var(--dark-brighter);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.auth-form__actions {
    width: 100%;
    text-align: center;
}

.auth-form__actions a {
    color: var(--light-blue-gray);
}

.auth-form__actions a:hover {
    color: var(--white);
}

.auth-form__actions-submit-wrapper {
    margin: 0 !important;
    width: 100%;
}

@media (min-width:768px) {
    .auth-form__actions-submit-wrapper {
        margin: 0 0 0 auto !important;
        width: auto;
    }
}

.auth-form__actions-submit-wrapper button {
    width: 100%;
    height: 36px;
}

@media (min-width:768px) {
    .auth-form__actions-submit-wrapper button {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        height: 48px;
    }
}

.auth-form__actions-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;
}
.auth-form__actions-recaptcha-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;
}

@media (min-width:768px) {
    .auth-form__actions-group {
        width: 100%;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 30px;
        gap: 0;
    }
    .auth-form__actions-recaptcha-wrapper {
        width: 100%;
        -webkit-box-orient: horizontal;
        /* -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse; */
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin-top: 30px;
        gap: 0;
    }
}

.auth-form__actions-group a {
    width: 100%;
}

@media (min-width:768px) {
    .auth-form__actions-group a {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

.auth-form__actions-group button {
    height: 36px;
    width: 100%;
}

@media (min-width:768px) {
    .auth-form__actions-group button {
        height: 48px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

.auth-form__providers-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
}

.auth-form__providers-wrapper button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.auth-form__form-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 40px;
}

@media (min-width:768px) {
    .auth-form__form-footer {
        margin-top: 50px;
    }
}

@media (min-width:1024px) {
    .auth-form__form-footer {
        margin-top: 35px;
    }
}

.auth-form__form-footer-links {
    margin-top: 66px;
}

@media (min-width:768px) {
    .auth-form__form-footer-links {
        margin-top: 140px;
    }
}

@media (min-width:1024px) {
    .auth-form__form-footer-links {
        display: none;
    }
}

.ine-link {
    font-weight: 450;
    font-size: .875rem;
    line-height: 20px;
    color: var(--chetwode-blue) !important;
}

.ine-link:hover {
    color: var(--purple-pale);
}

.ine-link:hover,
.ine-link:visited {
    -webkit-text-decoration-line: underline;
    text-decoration-line: underline;
}

.ine-link:visited {
    color: var(--butterfly-bush);
}

:root {
    --vs-controls-color: transparent !important;
    --vs-border-color: var(--emperor-gray) !important;
    --vs-dropdown-bg: var(--glass-mid-gray) !important;
    --vs-dropdown-color: var(--gray-nine) !important;
    --vs-dropdown-option-color: var(--gray-nine) !important;
    --vs-selected-bg: var(--light-blue-gray) !important;
    --vs-selected-color: var(--gray-nine) !important;
    --vs-search-input-color: var(--gray-nine) !important;
    --vs-dropdown-option--active-bg: var(--glass-mid-gray) !important;
    --vs-dropdown-option--active-color: var(--white) !important;
    --vs-dropdown-option-padding: 10px 20px !important;
    --vs-dropdown-menu-padding: 10px 20px !important;
}

.container {
    min-height: 100vh;
}

/*! CSS Used from: Embedded */
.cc-color-override-753489847.cc-window {
    color: rgb(255, 255, 255);
    background-color: rgb(20, 26, 32);
}

.cc-color-override-753489847 .cc-link,
.cc-color-override-753489847 .cc-link:active,
.cc-color-override-753489847 .cc-link:visited {
    color: rgb(255, 255, 255);
}

.cc-color-override-753489847 .cc-btn {
    color: rgb(255, 255, 255);
    border-color: transparent;
    background-color: rgb(239, 125, 34);
}

.cc-color-override-753489847 .cc-btn:hover,
.cc-color-override-753489847 .cc-btn:focus {
    background-color: rgb(255, 163, 72);
}

/*! CSS Used from: https://my.ine.com/css/chunk-7729a8ab.e9781b0d.css */
:root {
    --swiper-theme-color: #007aff;
}

:root {
    --swiper-navigation-size: 44px;
}

/*! CSS Used from: Embedded */
#MathJax_Message {
    position: fixed;
    left: 1em;
    bottom: 1.5em;
    background-color: #E6E6E6;
    border: 1px solid #959595;
    margin: 0px;
    padding: 2px 8px;
    z-index: 102;
    color: black;
    font-size: 80%;
    width: auto;
    white-space: nowrap;
}

.authentication-background {
    position: relative;
    background-image: url(https://my.ine.com/img/businessinvitation-bg.5ddce962.png);
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    min-height: 137px;
    height: 137px;
}

@media (min-width:768px) {
    .authentication-background {
        min-height: 180px;
        height: 180px;
    }
}

@media (min-width:1024px) {
    .authentication-background {
        width: 50%;
        min-height: 100vh;
        height: 100vh;
    }
}

.authentication-background__additional-links {
    display: none;
}

@media (min-width:1024px) {
    .authentication-background__additional-links {
        display: block;
    }
}

.title--no-tagline {
    padding: 0;
    margin-bottom: -20px;
}

/* auth form message */


.auth-form__verification-message{
    margin: 20px;
    color: #fff;
}


