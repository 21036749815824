/*! CSS Used from: https://my.ine.com/css/chunk-vendors.5c0c48fe.css */
.v-select {
  font-family: inherit;
  position: relative;
}
.v-select,
.v-select * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.vs__dropdown-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
  border-radius: var(--vs-border-radius);
  padding: 0 0 4px;
  white-space: normal;
}
.vs__dropdown-toggle,
.vs__selected-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.vs__selected-options {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative;
}
.vs__actions {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: var(--vs-actions-padding);
}
.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}
.vs__open-indicator {
  fill: var(--vs-controls-color);
  -webkit-transform: scale(var(--vs-controls-size));
  transform: scale(var(--vs-controls-size));
  -webkit-transition: -webkit-transform var(--vs-transition-duration)
    var(--vs-transition-timing-function);
  transition: -webkit-transform var(--vs-transition-duration) var(--vs-transition-timing-function);
  transition: transform var(--vs-transition-duration) var(--vs-transition-timing-function);
  transition: transform var(--vs-transition-duration) var(--vs-transition-timing-function),
    -webkit-transform var(--vs-transition-duration) var(--vs-transition-timing-function);
  -webkit-transition-timing-function: var(--vs-transition-timing-function);
  transition-timing-function: var(--vs-transition-timing-function);
}
.vs__clear {
  fill: var(--vs-controls-color);
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-right: 8px;
  padding: 0;
}
.vs__search,
.vs__search:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 1px solid transparent;
  border-left: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--vs-search-input-color);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: var(--vs-font-size);
  line-height: var(--vs-line-height);
  margin: 4px 0 0;
  max-width: 100%;
  outline: none;
  padding: 0 7px;
  width: 0;
  z-index: 1;
}
.vs__search::placeholder {
  color: var(--vs-search-input-placeholder-color);
}
.vs--unsearchable .vs__search {
  opacity: 1;
}
.vs--unsearchable:not(.vs--disabled) .vs__search {
  cursor: pointer;
}
.vs__spinner {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-animation: vSelectSpinner 1.1s linear infinite;
  animation: vSelectSpinner 1.1s linear infinite;
  border: 0.9em solid hsla(0, 0%, 39%, 0.1);
  border-left-color: rgba(60, 60, 60, 0.45);
  font-size: 5px;
  opacity: 0;
  overflow: hidden;
  text-indent: -9999em;
  -webkit-transform: translateZ(0) scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  transform: translateZ(0) scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}
.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  height: 5em;
  -webkit-transform: scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  transform: scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  width: 5em;
}
/*! CSS Used from: https://my.ine.com/css/app.70cff028.css */
a {
  background-color: transparent;
}
small {
  font-size: 80%;
}
button,
input,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button {
  text-transform: none;
}
[type="button"],
[type="submit"],
button {
  -webkit-appearance: button;
}
textarea {
  overflow: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
* {
  text-decoration: none;
  outline: none !important;
}
*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
h3 {
  font-weight: 400;
}
h3 {
  font-size: 3rem;
  line-height: 3.125rem;
  letter-spacing: normal;
}
button,
input {
  overflow: visible;
}
button,
input,
textarea {
  font: inherit;
  margin: 0;
}
.btn {
  --btn-height: 48px;
  --btn-side-pad: 24px;
  --btn-border-radius: 8px;
  --btn-bg-color: var(--white, #fff);
  --btn-active-bg-color: var(--athens-gray);
  --btn-font-size: 1rem;
  --btn-text-color: var(--charcoal, #0c0c0c);
  --btn-border-color: var(--emperor-gray, #555);
  --btn-hover-border-color: var(--white, #fff);
  --btn-secondary-text-color: var(--white, #fff);
  --btn-secondary-active-text-color: var(--charcoal, #0c0c0c);
  --btn-icon-size: 24px;
  --btn-disabled-opacity: 0.2;
  --btn-promo-text-color: var(--glass-purple);
  --btn-promo-active-text-color: var(--charcoal, #0c0c0c);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  height: var(--btn-height, 48px);
  padding: 0 var(--btn-side-pad, 24px);
  border-radius: var(--btn-border-radius, 8px);
  background-color: var(--btn-bg-color, var(--white, #fff));
  border: none;
  font-size: var(--btn-font-size, 1rem);
  color: var(--btn-text-color, var(--charcoal, #0c0c0c));
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  will-change: background-color, border-color;
  cursor: pointer;
  white-space: nowrap;
}
.btn.btn--secondary {
  --btn-bg-color: transparent;
  --btn-text-color: var(--btn-secondary-text-color);
  border: 1px solid var(--btn-border-color);
}
.btn.btn--secondary:active,
.btn.btn--secondary:hover {
  --btn-border-color: var(--btn-hover-border-color) !important;
}
.btn.btn--secondary:active {
  --btn-bg-color: var(--btn-border-color) !important;
  --btn-text-color: var(--btn-secondary-active-text-color) !important;
}
.btn:disabled,
.btn[disabled] {
  opacity: var(--btn-disabled-opacity);
  pointer-events: none;
}
.dialog-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9019607843137255);
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.dialog-modal__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding: 30px;
  border-radius: 24px;
  top: 20%;
  margin: 0 auto;
  background: var(--glass-mid-gray);
  background-color: var(--darker-gray);
  color: var(--white);
  width: 100%;
  max-width: 660px;
  min-height: 200px;
}
.dialog-modal__close {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
}
.dialog-modal__close--icon {
  fill: var(--white);
}
.dialog-modal .action-buttons {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.dialog-modal .action-buttons button {
  margin-top: 10px;
  margin-left: 10px;
}
:root .vs__open-indicator {
  margin-right: 8px;
}
:root .vue-select .vs__dropdown-toggle,
:root .vue-select .vs__search::placeholder,
:root .vue-select .vs__search:disabled {
  background-color: var(--darker-gray);
  border: none;
  color: var(--light-blue-gray);
  font-family: var(--font-regular);
}
:root .vue-select .vs__selected-options {
  margin-left: 4px;
}
:root .vue-select .vs__dropdown-toggle {
  height: 46px;
  font-size: 0.75rem;
  font-family: var(--font-book);
  padding-left: 4px;
  border: 1px solid var(--emperor-gray);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: var(--gray-light-box-shadow);
  box-shadow: var(--gray-light-box-shadow);
}
:root .vue-select .vs__dropdown-toggle:focus {
  border-color: var(--flat-black);
  -webkit-box-shadow: var(--purple-dark-box-shadow);
  box-shadow: var(--purple-dark-box-shadow);
}
:root .vue-select .vs__dropdown-toggle:hover {
  border-color: var(--perfect-gray);
}
/*! CSS Used from: https://my.ine.com/css/chunk-c399de50.712446a0.css */
.send-issue[data-v-d0d56558] {
  color: var(--white);
}
.send-issue[data-v-d0d56558] .dialog-modal__wrapper {
  color: var(--white);
  padding: 0;
}
@media (min-width: 1024px) {
  .send-issue[data-v-d0d56558] .dialog-modal__wrapper {
    max-width: 530px;
  }
}
.send-issue[data-v-d0d56558] .dialog-modal__wrapper .action-buttons {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.send-issue__title[data-v-d0d56558] {
  margin: 18px auto;
  font-size: 1.125rem;
  line-height: 1.875rem;
}
@media (min-width: 768px) {
  .send-issue__title[data-v-d0d56558] {
    line-height: 3.125rem;
  }
}
.send-issue__form[data-v-d0d56558] {
  padding: 2rem 2rem;
}
.send-issue__type[data-v-d0d56558] {
  position: relative;
  margin: 0 0 30px 0;
}
.send-issue__comment-textarea[data-v-d0d56558] {
  color: var(--white);
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  padding: 5px;
  width: 100%;
}
.send-issue__comment small[data-v-d0d56558] {
  color: var(--white);
  font-size: 12px;
}
.send-issue__comment a[data-v-d0d56558] {
  color: var(--white);
}
.send-issue h3[data-v-d0d56558] {
  font-size: 18px;
  width: 100%;
}
.send-issue__form-field[data-v-d0d56558] {
  background: var(--darker-gray);
  border: 1px solid var(--emperor-gray);
}
/*! CSS Used from: https://my.ine.com/css/chunk-630b9279.711a1246.css */
.send-issue[data-v-d0d56558] {
  color: var(--white);
}
.send-issue[data-v-d0d56558] .dialog-modal__wrapper {
  color: var(--white);
  padding: 0;
}
@media (min-width: 1024px) {
  .send-issue[data-v-d0d56558] .dialog-modal__wrapper {
    max-width: 530px;
  }
}
.send-issue[data-v-d0d56558] .dialog-modal__wrapper .action-buttons {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.send-issue__title[data-v-d0d56558] {
  margin: 18px auto;
  font-size: 1.125rem;
  line-height: 1.875rem;
}
@media (min-width: 768px) {
  .send-issue__title[data-v-d0d56558] {
    line-height: 3.125rem;
  }
}
.send-issue__form[data-v-d0d56558] {
  padding: 2rem 2rem;
}
.send-issue__type[data-v-d0d56558] {
  position: relative;
  margin: 0 0 30px 0;
}
.send-issue__comment-textarea[data-v-d0d56558] {
  color: var(--white);
  background: none;
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 4px;
  padding: 5px;
  width: 100%;
}
.send-issue__comment small[data-v-d0d56558] {
  color: var(--white);
  font-size: 12px;
}
.send-issue__comment a[data-v-d0d56558] {
  color: var(--white);
}
.send-issue h3[data-v-d0d56558] {
  font-size: 18px;
  width: 100%;
}
.send-issue__form-field[data-v-d0d56558] {
  background: var(--darker-gray);
  border: 1px solid var(--emperor-gray);
}
/*! CSS Used keyframes */
@-webkit-keyframes vSelectSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes vSelectSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.popup-container {
  text-align: center;
  /* padding: 20px; */
  /* border: 1px solid #ccc; */
  /* border-radius: 8px;
    max-width: 400px;
    margin: 0 auto; */
}

.send-issue__title {
  font-size: 40px;
  margin-bottom: 10px;
  font-family: var(--font-bold);
}

h6 {
  font-size: 16px;
  margin-top: 0;
}
