/*! CSS Used from: https://my.ine.com/css/app.70cff028.css */
button{font-family:inherit;font-size:100%;line-height:1.15;margin:0;}
button{overflow:visible;}
button{text-transform:none;}
button{-webkit-appearance:button;}
*{text-decoration:none;outline:none!important;}
*,:after,:before{-webkit-box-sizing:inherit;box-sizing:inherit;}
p{margin:0 0 16px;}
button{overflow:visible;}
button{font:inherit;margin:0;}
.btn{--btn-height:48px;--btn-side-pad:24px;--btn-border-radius:8px;--btn-bg-color:var(--white,#fff);--btn-active-bg-color:var(--athens-gray);--btn-font-size:1rem;--btn-text-color:var(--charcoal,#0c0c0c);--btn-border-color:var(--emperor-gray,#555);--btn-hover-border-color:var(--white,#fff);--btn-secondary-text-color:var(--white,#fff);--btn-secondary-active-text-color:var(--charcoal,#0c0c0c);--btn-icon-size:24px;--btn-disabled-opacity:0.2;--btn-promo-text-color:var(--glass-purple);--btn-promo-active-text-color:var(--charcoal,#0c0c0c);-webkit-box-sizing:border-box;box-sizing:border-box;position:relative;display:-webkit-inline-box;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-ms-flex-negative:0;flex-shrink:0;-webkit-column-gap:10px;-moz-column-gap:10px;column-gap:10px;height:var(--btn-height,48px);padding:0 var(--btn-side-pad,24px);border-radius:var(--btn-border-radius,8px);background-color:var(--btn-bg-color,var(--white,#fff));border:none;font-size:var(--btn-font-size,1rem);color:var(--btn-text-color,var(--charcoal,#0c0c0c));-webkit-transition:all .3s;transition:all .3s;will-change:background-color,border-color;cursor:pointer;white-space:nowrap;}
.btn svg{width:var(--btn-icon-size,24px);height:var(--btn-icon-size,24px);-ms-flex-negative:0;flex-shrink:0;}
.btn.btn--tertiary{--btn-bg-color:transparent;--btn-text-color:var(--btn-secondary-text-color);}
.btn.btn--tertiary:hover{--btn-bg-color:hsla(0,0%,100%,0.1);}
.btn.btn--tertiary:active{--btn-bg-color:hsla(0,0%,100%,0.2);}
.btn:disabled{opacity:var(--btn-disabled-opacity);pointer-events:none;}
.flat-scroll ::-webkit-scrollbar{-webkit-appearance:none;width:16px;height:16px;}
.flat-scroll ::-webkit-scrollbar-corner,.flat-scroll ::-webkit-scrollbar-track{background:hsla(0,0%,49.8%,.05);}
.flat-scroll ::-webkit-scrollbar-thumb{background-color:hsla(0,0%,49.8%,.25);background-clip:content-box;border:4px solid hsla(0,0%,49.8%,0);border-radius:12px;}
.flat-scroll ::-webkit-scrollbar-thumb:active,.flat-scroll ::-webkit-scrollbar-thumb:hover{background-color:hsla(0,0%,49.8%,.4);}
/*! CSS Used from: https://my.ine.com/css/chunk-166abc54.c32de71e.css */
.global-session{max-width:100%;width:100%;background:linear-gradient(111.02deg,rgba(18,18,18,.7) 3.86%,rgba(18,18,18,0) 107.96%);border:1px solid var(--tundora-gray);-webkit-box-sizing:border-box;box-sizing:border-box;border-radius:10px;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-ms-flex-direction:column;flex-direction:column;-ms-flex-item-align:stretch;align-self:stretch;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;-webkit-box-pack:center;-ms-flex-pack:center;justify-content:center;padding:30px;position:relative;overflow:hidden;}
@media (min-width:1024px){
.global-session{max-width:550px;}
}
.global-session__body{text-align:left;}
.global-session__subtitle{font-weight:700;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:start;-ms-flex-align:start;align-items:flex-start;margin-bottom:10px;color:var(--white);}
.global-session__text{margin-bottom:10px;}
.global-session__session-running{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:start;-ms-flex-pack:start;justify-content:flex-start;}
.global-session__session-running .global-session__flat-btn svg{margin-left:5px;fill:var(--light-blue-gray);}
.global-session__session-running .global-session__flat-btn svg path{fill:transparent;stroke:var(--light-blue-gray);}
.global-session__flat-btn{padding:5px 9px;}
.global-session__flat-btn-text{color:var(--light-blue-gray);font-size:.875rem;padding-top:3px;}
.global-session__lab-running{color:var(--white);margin-bottom:20px;}
.pta-card-session__session-details{margin-top:30px;}

.btn-adjst{
    height: 40px;
}