.missing {
    background-image: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(63 58 58 / 9%) 100%), url(https://s3.amazonaws.com/my.ine.com/assets/error/404_planet.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 250px;
}

@media (min-width:2046px) {
    .missing {
        background-image: linear-gradient(to bottom, rgb(0 0 0 / 0%) 0%, rgb(24 24 24 / 67%) 100%), url(https://s3.amazonaws.com/my.ine.com/assets/error/404_planet.jpg);
    }
}   

.missing__error-msg-wrapper,
.missing__spiff-wrapper {
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

.missing__error-msg {
    color: var(--white);
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
}

.missing__home-btn-wrapper {
    margin-top: 50px;
}

.missing__home-btn {
    text-decoration: none;
    color: var(--white);
    font-size: 1rem;
    text-transform: uppercase;
    border: 3px solid var(--orange);
    padding: 10px 20px;
    border-radius: 30px;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

.missing__home-btn:hover {
    color: var(--black);
    background-color: var(--white);
}