:root {
    --vs-colors--lightest: rgba(60, 60, 60, 0.26);
    --vs-colors--light: rgba(60, 60, 60, 0.5);
    --vs-colors--dark: #333;
    --vs-colors--darkest: rgba(0, 0, 0, 0.15);
    --vs-search-input-color: inherit;
    --vs-search-input-placeholder-color: inherit;
    --vs-font-size: 1rem;
    --vs-line-height: 1.4;
    --vs-state-disabled-bg: #f8f8f8;
    --vs-state-disabled-color: var(--vs-colors--light);
    --vs-state-disabled-controls-color: var(--vs-colors--light);
    --vs-state-disabled-cursor: not-allowed;
    --vs-border-color: var(--vs-colors--lightest);
    --vs-border-width: 1px;
    --vs-border-style: solid;
    --vs-border-radius: 4px;
    --vs-actions-padding: 4px 6px 0 3px;
    --vs-controls-color: var(--vs-colors--light);
    --vs-controls-size: 1;
    --vs-controls--deselect-text-shadow: 0 1px 0 #fff;
    --vs-selected-bg: #f0f0f0;
    --vs-selected-color: var(--vs-colors--dark);
    --vs-selected-border-color: var(--vs-border-color);
    --vs-selected-border-style: var(--vs-border-style);
    --vs-selected-border-width: var(--vs-border-width);
    --vs-dropdown-bg: #fff;
    --vs-dropdown-color: inherit;
    --vs-dropdown-z-index: 1000;
    --vs-dropdown-min-width: 160px;
    --vs-dropdown-max-height: 350px;
    --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
    --vs-dropdown-option-bg: #000;
    --vs-dropdown-option-color: var(--vs-dropdown-color);
    --vs-dropdown-option-padding: 3px 20px;
    --vs-dropdown-option--active-bg: #5897fb;
    --vs-dropdown-option--active-color: #fff;
    --vs-dropdown-option--deselect-bg: #fb5858;
    --vs-dropdown-option--deselect-color: #fff;
    --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
    --vs-transition-duration: 150ms;
}

:root {
    --vs-transition-timing-function: cubic-bezier(1, 0.5, 0.8, 1);
    --vs-transition-duration: 0.15s;
}

:root {
    --vs-disabled-bg: var(--vs-state-disabled-bg);
    --vs-disabled-color: var(--vs-state-disabled-color);
    --vs-disabled-cursor: var(--vs-state-disabled-cursor);
}

:root {
    --orange: #ef7d22;
    --gray: grey;
    --bright-gray: #aaa;
    --light-gray: #262626;
    --light-gray-darker: #57575c;
    --light-elevation-200: rgba(55, 65, 81, 0.0784313725490196);
    --dark-gray: #151515;
    --darker-gray: #222;
    --business-gray: #aeaeb2;
    --gallery: #eee;
    --shark: #2a282a;
    --mulled-wine-50: rgba(75, 72, 103, 0.5019607843137255);
    --primary-ine-gradient: linear-gradient(92.23deg, #ef7d22 3.65%, #ef741d 25.47%, #ee5a10 62.46%, #ee3b00 98.5%);
    --glass-mid-gray: linear-gradient(104.99deg, rgba(62, 63, 73, 0.7) -13.91%, rgba(62, 63, 73, 0) 100.48%);
    --glass-mid-gray-light: linear-gradient(112.2deg, rgba(187, 188, 199, 0.4) -46.39%, rgba(187, 188, 199, 0) 112.38%);
    --glass-solid-mid-gray: linear-gradient(89.99deg, #777 0.5%, #2e2e2e 97.14%);
    --glass-light-gray: linear-gradient(112.2deg, rgba(187, 188, 199, 0.7) -46.39%, rgba(187, 188, 199, 0) 112.38%);
    --glass-extra-light: linear-gradient(112.2deg, rgba(187, 188, 199, 0.4) -46.39%, rgba(187, 188, 199, 0) 112.38%);
    --glass-dark: linear-gradient(111.02deg, rgba(18, 18, 18, 0.7) 3.86%, rgba(18, 18, 18, 0) 107.96%);
    --glass-purple: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    --glass-red: linear-gradient(180deg, #c31432 3.12%, rgba(195, 20, 50, 0));
    --glass-pink-violet: linear-gradient(96.51deg, #cf3ad2 7.05%, #5936e4 93.87%);
    --highlight-gray: #2e2e2e;
    --lighter-gray: #717171;
    --white: #fff;
    --white-10: hsla(0, 0%, 100%, 0.10196078431372549);
    --white-20: hsla(0, 0%, 100%, 0.2);
    --white-25: hsla(0, 0%, 100%, 0.25098039215686274);
    --flat-black: #333;
    --black: #000;
    --black-50: rgba(0, 0, 0, 0.5019607843137255);
    --black-footer: #010101;
    --little-black: #292929;
    --red: red;
    --error-background: 20, 26, 32, 0.9;
    --notification-box-shadow: 0px 0px 16px rgba(5, 73, 152, 0.38823529411764707);
    --green: #00dc9b;
    --yellow: #eeecb4;
    --dark-yellow: #edbd02;
    --border-gray: #eaeaea;
    --border-dark-gray: #acacac;
    --purple: #b53dc5;
    --secondary-promo-purple: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
    --blue-gem-gradient: linear-gradient(358.73deg, rgba(61, 36, 165, 0) 13.2%, #3d24a5 99.82%);
    --blue: #2c6ed0;
    --lochmara: #0073cb;
    --menu-background: #1d1d1d;
    --menu-btn: #333;
    --menu-detail: #989898;
    --menu-subcategory: #777;
    --menu-gradient: linear-gradient(180deg, #111, rgba(17, 17, 17, 0));
    --manage-form-input: #272727;
    --manage-form-placeholder: #aaa;
    --lab-status-green: #22d895;
    --lab-status-yellow: #ffde29;
    --lab-status-red: #fd352e;
    --tango: #ef7d23;
    --highlighter: #ffc504;
    --highlighted: #f83333;
    --metric-gradient: radial-gradient(closest-side at 50% 50%, #215bc7 0%, rgba(33, 19, 177, 0) 100%) 0% 0% no-repeat padding-box;
    --cert-gray: #252525;
    --charcoal: #1e1e1e;
    --free-alt-bg: #f7f8fa;
    --off-white: #f7f8f9;
    --error: red;
    --detail: #acb2bf;
    --input-background: #111720;
    --input-border: #29285a;
    --cart-background: 20, 26, 32, 0.9;
    --cod-gray: #1c1c1c;
    --dove-gray: #666;
    --silver-gray: #8b8b8b;
    --emperor-gray: #555;
    --payment-border: #202245;
    --payment-border-selected: #3f4388;
    --payment-box-shadow: 0px 0px 16px rgba(5, 73, 152, 0.38823529411764707);
    --checkbox-border: #30336a;
    --tundora-gray: #444;
    --silver-chalice: #b1b1b1;
    --light-blue-gray: #afb0bf;
    --perfect-gray: #888;
    --athens-gray: #e9e9ed;
    --positive-light: #70ffd4;
    --cornflower-blue: #88a9ff;
    --silver-chalice-2: #aaa;
    --mid-gray: #bbbcc7;
    --mid-gray-dark: #797b8c;
    --mid-gray-dark-25: rgba(121, 123, 140, 0.25098039215686274);
    --dark-brighter: #111;
    --aubergine: #221f20;
    --red-gradient: linear-gradient(90deg, #db4133 8310%, #de5936 11665.11%, #e27339 15935.25%, #e37c3a 18477%);
    --gray-lavender: #717071;
    --gray-nine: #999;
    --gray-storm: #797b8c;
    --chetwode-blue: #a793f6;
    --chetwode-blue-50: rgba(167, 147, 246, 0.5019607843137255);
    --orange-gradient: linear-gradient(180.18deg, #db4133 -5.52%, #de5936 36.62%, #e27339 90.25%, #e37c3a 122.17%);
    --oranger-gradient: linear-gradient(92.23deg, #ef7d22 3.65%, #ef741d 25.47%, #ee5a10 62.46%, #ee3b00 98.5%);
    --orangest-horizontal-gradient: linear-gradient(90deg, #db4133, #de5935 33%, #e17238 75%, #e37c3a);
    --orangest-vertical-gradient: linear-gradient(180.18deg, #db4133 -5.52%, #de5935 36.62%, #e17238 90.25%, #e37c3a 122.17%);
    --purple-gradient: linear-gradient(96.51deg, #863ad2 7.05%, #5936e4 93.87%);
    --purple-horizontal-gradient: linear-gradient(270deg, #803995 0.01%, #ea2426 100.01%);
    --purple-vertical-gradient: linear-gradient(0deg, #803995 0.01%, #ea2426 100.01%);
    --purple-pale: #c9bbff;
    --dark-blue: #190da0;
    --caribbean-green: #49e0b3;
    --carnation: #ff5e5e;
    --butterfly-bush: #5b4b9b;
    --shark-gray: #2a2a2f;
    --pale-yellow: #fffec9;
    --light-yellow: #fce57f;
    --performance-light: #f8b369;
    --concrete: #f2f2f2;
    --melrose: #c4b7f9;
    --white-light-box-shadow: 0px 4px 40px hsla(0, 0%, 100%, 0.4);
    --white-medium-box-shadow: 0px 0px 19px hsla(0, 0%, 100%, 0.26);
    --gray-light-box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
    --gray-light-box-shadow-filter: drop-shadow(0px 1px 2px rgba(55, 65, 81, 0.08));
    --carnation-box-shadow: 0px 0px 0px 1px rgba(255, 94, 94, 0.3), inset 0px 0px 0px 1px #ff5e5e;
    --purple-dark-box-shadow: 0px 0px 0px 1px #5b4b9b, inset 0px 0px 0px 1px #a793f6;
    --purple-light: #a793f6;
    --purple-light-box-shadow: 0px 0px 10px rgba(167, 147, 246, 0.5);
    --positive-light-shadow: 0px 0px 10px var(--positive-light);
    --violet-light: #d7cdff;
    --fog: #d3c9fa;
    --larea-networking: #fad740;
    --larea-cloud: #88a9ff;
    --larea-data-science: #66c19a;
    --larea-cyber-security: #bb85ff;
    --larea-it-essentials: #f647c0;
    --larea-development: #d73f47;
    --font-book: "CircularStd-Book";
    --font-bold: "CircularStd-Bold";
    --font-medium: "CircularStd-Medium";
    --font-roboto: "RobotoMono-Regular";
}

:root {
    --vs-controls-color: transparent !important;
    --vs-border-color: var(--emperor-gray) !important;
    --vs-dropdown-bg: var(--glass-mid-gray) !important;
    --vs-dropdown-color: var(--gray-nine) !important;
    --vs-dropdown-option-color: var(--gray-nine) !important;
    --vs-selected-bg: var(--light-blue-gray) !important;
    --vs-selected-color: var(--gray-nine) !important;
    --vs-search-input-color: var(--gray-nine) !important;
    --vs-dropdown-option--active-bg: var(--glass-mid-gray) !important;
    --vs-dropdown-option--active-color: var(--white) !important;
    --vs-dropdown-option-padding: 10px 20px !important;
    --vs-dropdown-menu-padding: 10px 20px !important;
}

:root {
    --swiper-theme-color: #007aff;
}

:root {
    --swiper-navigation-size: 44px;
}

@font-face {
    font-family: 'CircularStd-Medium';
    src: url('fonts/CircularStd-Medium.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'CircularStd-Bold';
    src: url('fonts/CircularStd-Bold.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
@font-face {
    font-family: 'CircularStd-Book';
    src: url('fonts/CircularStd-Book.otf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

/* body {
    font-family: "CircularStd-Book","Helvetica","Arial",sans-serif !important; 
}

input {
    font-family: "CircularStd-Book","Helvetica","Arial",sans-serif !important; 
} */

.showcase_text{
    font-size: 28px;
    color: #fff;
    margin-left: 10px;
    margin-top: 5px;
    font-weight: 600;
}
@media screen and (max-width: 768px) {
    .showcase_text{
        font-size: 20px;
    }
}
@media screen and (max-width: 480px) {
    .showcase_text{
        font-size: 13px;
    }
    
}


.mobile-navbar__loginbtn-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
  }
  
  .mobile-navbar__signin-signout {
    display: flex;
    align-items: center;
    margin-right: 10px; /* Optional spacing between the buttons */
  }