.cc-window {
  opacity: 1;
  -webkit-transition: opacity 1s ease;
  transition: opacity 1s ease;
}

.cc-window.cc-invisible {
  opacity: 0;
}

.cc-window {
  position: fixed;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Helvetica, Calibri, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  z-index: 9999;
}

.cc-window.cc-floating {
  padding: 2em;
  max-width: 24em;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.cc-btn,
.cc-link {
  cursor: pointer;
}

.cc-link {
  opacity: 0.8;
  display: inline-block;
  padding: 0.2em;
  text-decoration: underline;
}

.cc-link:hover {
  opacity: 1;
}

.cc-link:active,
.cc-link:visited {
  color: initial;
}

.cc-btn {
  display: block;
  padding: 0.4em 0.8em;
  font-size: 0.9em;
  font-weight: 700;
  border-width: 2px;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
}

.cc-right {
  right: 1em;
}

.cc-bottom {
  bottom: 1em;
}

.cc-floating .cc-message {
  display: block;
  margin-bottom: 1em;
}

.cc-window.cc-floating .cc-compliance {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
}

.cc-compliance {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: justify;
  align-content: space-between;
}

.cc-floating .cc-compliance > .cc-btn {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media print {
  .cc-window {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait),
  screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-bottom {
    bottom: 0;
  }

  .cc-window.cc-floating,
  .cc-window.cc-right {
    left: 0;
    right: 0;
  }

  .cc-window.cc-floating {
    max-width: none;
  }

  .cc-window .cc-message {
    margin-bottom: 1em;
  }
}

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
}

.cc-theme-classic .cc-btn {
  border-radius: 5px;
}

.cc-theme-classic .cc-btn:last-child {
  min-width: 140px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block;
}

/*! CSS Used from: https://my.ine.com/css/chunk-vendors.8952340f.css */
:root {
  --vs-colors--lightest: rgba(60, 60, 60, 0.26);
  --vs-colors--light: rgba(60, 60, 60, 0.5);
  --vs-colors--dark: #333;
  --vs-colors--darkest: rgba(0, 0, 0, 0.15);
  --vs-search-input-color: inherit;
  --vs-search-input-placeholder-color: inherit;
  --vs-font-size: 1rem;
  --vs-line-height: 1.4;
  --vs-state-disabled-bg: #f8f8f8;
  --vs-state-disabled-color: var(--vs-colors--light);
  --vs-state-disabled-controls-color: var(--vs-colors--light);
  --vs-state-disabled-cursor: not-allowed;
  --vs-border-color: var(--vs-colors--lightest);
  --vs-border-width: 1px;
  --vs-border-style: solid;
  --vs-border-radius: 4px;
  --vs-actions-padding: 4px 6px 0 3px;
  --vs-controls-color: var(--vs-colors--light);
  --vs-controls-size: 1;
  --vs-controls--deselect-text-shadow: 0 1px 0 #fff;
  --vs-selected-bg: #f0f0f0;
  --vs-selected-color: var(--vs-colors--dark);
  --vs-selected-border-color: var(--vs-border-color);
  --vs-selected-border-style: var(--vs-border-style);
  --vs-selected-border-width: var(--vs-border-width);
  --vs-dropdown-bg: #fff;
  --vs-dropdown-color: inherit;
  --vs-dropdown-z-index: 1000;
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 350px;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px var(--vs-colors--darkest);
  --vs-dropdown-option-bg: #000;
  --vs-dropdown-option-color: var(--vs-dropdown-color);
  --vs-dropdown-option-padding: 3px 20px;
  --vs-dropdown-option--active-bg: #5897fb;
  --vs-dropdown-option--active-color: #fff;
  --vs-dropdown-option--deselect-bg: #fb5858;
  --vs-dropdown-option--deselect-color: #fff;
  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --vs-transition-duration: 150ms;
}

.v-select {
  font-family: inherit;
  position: relative;
}

.v-select,
.v-select * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {
  --vs-transition-timing-function: cubic-bezier(1, 0.5, 0.8, 1);
  --vs-transition-duration: 0.15s;
}

:root {
  --vs-disabled-bg: var(--vs-state-disabled-bg);
  --vs-disabled-color: var(--vs-state-disabled-color);
  --vs-disabled-cursor: var(--vs-state-disabled-cursor);
}

.vs__dropdown-toggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
  border-radius: var(--vs-border-radius);
  padding: 0 0 4px;
  white-space: normal;
}

.vs__dropdown-toggle,
.vs__selected-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.vs__selected-options {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 2px;
  position: relative;
}

.vs__actions {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: var(--vs-actions-padding);
}

.vs--unsearchable .vs__dropdown-toggle {
  cursor: pointer;
}

.vs__open-indicator {
  fill: var(--vs-controls-color);
  -webkit-transform: scale(var(--vs-controls-size));
  transform: scale(var(--vs-controls-size));
  -webkit-transition: -webkit-transform var(--vs-transition-duration)
    var(--vs-transition-timing-function);
  transition: -webkit-transform var(--vs-transition-duration) var(--vs-transition-timing-function);
  transition: transform var(--vs-transition-duration) var(--vs-transition-timing-function);
  transition: transform var(--vs-transition-duration) var(--vs-transition-timing-function),
    -webkit-transform var(--vs-transition-duration) var(--vs-transition-timing-function);
  -webkit-transition-timing-function: var(--vs-transition-timing-function);
  transition-timing-function: var(--vs-transition-timing-function);
}

.vs__clear {
  fill: var(--vs-controls-color);
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-right: 8px;
  padding: 0;
}

.vs__selected {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--vs-selected-bg);
  border: var(--vs-selected-border-width) var(--vs-selected-border-style)
    var(--vs-selected-border-color);
  border-radius: var(--vs-border-radius);
  color: var(--vs-selected-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: var(--vs-line-height);
  margin: 4px 2px 0;
  padding: 0 0.25em;
  z-index: 0;
}

.vs--single .vs__selected {
  background-color: transparent;
  border-color: transparent;
}

.vs__search,
.vs__search:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 1px solid transparent;
  border-left: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--vs-search-input-color);
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: var(--vs-font-size);
  line-height: var(--vs-line-height);
  margin: 4px 0 0;
  max-width: 100%;
  outline: none;
  padding: 0 7px;
  width: 0;
  z-index: 1;
}

.vs__search::placeholder {
  color: var(--vs-search-input-placeholder-color);
}

.vs--unsearchable .vs__search {
  opacity: 1;
}

.vs--unsearchable:not(.vs--disabled) .vs__search {
  cursor: pointer;
}

.vs__spinner {
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-animation: vSelectSpinner 1.1s linear infinite;
  animation: vSelectSpinner 1.1s linear infinite;
  border: 0.9em solid hsla(0, 0%, 39%, 0.1);
  border-left-color: rgba(60, 60, 60, 0.45);
  font-size: 5px;
  opacity: 0;
  overflow: hidden;
  text-indent: -9999em;
  -webkit-transform: translateZ(0) scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  transform: translateZ(0) scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  -webkit-transition: opacity 0.1s;
  transition: opacity 0.1s;
}

.vs__spinner,
.vs__spinner:after {
  border-radius: 50%;
  height: 5em;
  -webkit-transform: scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  transform: scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
  width: 5em;
}

/*! CSS Used from: https://my.ine.com/css/app.ad2cd678.css */
.mega-menu {
  display: none;
}

@media (min-width: 1024px) {
  .mega-menu {
    display: block;
    width: 100%;
    background: var(--menu-background);
  }
}

.mega-menu__categories {
  background: var(--menu-background);
  border-radius: 10px;
  position: absolute;
  top: 77px;
  left: -42px;
  width: 231px;
}

.mega-menu__categories-list {
  background: var(--glass-mid-gray);
  border-radius: 10px;
}

.mega-menu__categories-item {
  color: var(--white);
  font-family: var(--font-book);
  font-size: 0.875rem;
  line-height: 20px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 15px 22.5px 15px 20px;
  white-space: nowrap;
  cursor: pointer;
}

.mega-menu__categories-item:first-child,
.mega-menu__categories-item:first-child:after {
  border-radius: 10px 10px 0 0;
}

.mega-menu__categories-item:after {
  position: absolute;
  content: "";
  background: var(--glass-extra-light);
  opacity: 0;
  height: 80px;
  width: 100%;
  left: 0;
}

.mega-menu__categories-item:hover .mega-menu__categories-arrow {
  display: block;
}

.mega-menu__categories-item:hover:after {
  opacity: 1;
  -webkit-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.mega-menu__categories-item--text {
  margin: 0 0 0 15px;
}

.mega-menu__categories-item:last-child:after {
  border-radius: 0 0 10px 10px;
}

.mega-menu__categories-arrow {
  margin-left: auto;
  height: 15px;
  width: 15px;
  display: none;
}

.mega-menu__arrows {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-right: 7px solid transparent;
  border-top: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid var(--shark-gray);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.mega-menu__arrows-container {
  position: absolute;
  content: "";
  left: -42px;
  bottom: -7px;
  width: 231px;
  height: 14px;
  z-index: -1;
}

.mega-menu__arrows-1 {
  top: 0;
}

.profile-progress {
  position: relative;
}

.profile-progress__toggle-btn {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  background: transparent;
  color: var(--white);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
  padding: 0;
}

.profile-progress__toggle-btn:hover {
  cursor: pointer;
}

.profile-progress__image {
  width: 100%;
  min-width: 45px;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 100%;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 12%;
}

.profile-progress__ellipsis {
  position: absolute;
  stroke: hsla(0, 0%, 50.2%, 0.329);
}

.profile-progress__ellipsis--25 {
  -webkit-transform: rotate(277deg);
  transform: rotate(277deg);
}

.profile-progress__ellipsis--50 {
  -webkit-transform: rotate(7deg);
  transform: rotate(7deg);
}

.profile-progress__ellipsis--75 {
  -webkit-transform: rotate(97deg);
  transform: rotate(97deg);
}

.profile-progress__ellipsis--100 {
  -webkit-transform: rotate(187deg);
  transform: rotate(187deg);
}

.profile-progress__ellipsis--completed {
  stroke: var(--orange);
}

.profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.profile__toggle-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 25px;
  background-color: transparent;
}

@media (min-width: 768px) {
  .profile__toggle-wrapper {
    height: 40px;
    padding: 0 13px 0 0;
  }
}

.profile__toggle-wrapper:hover {
  cursor: pointer;
}

.profile__info {
  display: none;
}

@media (min-width: 768px) {
  .profile__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 7.5px 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: end;
  }

  .profile__info-fullname {
    font-size: 1rem;
    color: var(--white);
    line-height: 18px;
  }

  .profile__info-progress {
    font-size: 0.625rem;
    color: var(--orange);
    line-height: 12px;
  }

  .profile__info-business {
    font-size: 0.75rem;
    color: var(--business-gray);
  }
}

.profile__progress {
  width: 45px;
  min-width: 45px;
  height: 45px;
  margin-left: 5px;
}

@media (min-width: 768px) {
  .profile__progress {
    margin-left: 15px;
  }
}

.profile__icon {
  width: 100%;
  margin-left: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: var(--white);
  padding: 10px;
  height: 44px;
  border-radius: 5px;
}

@media (min-width: 768px) {
  .profile__icon {
    width: auto;
    display: block;
    margin-left: 10px;
  }
}

.profile__icon:active,
.profile__icon:hover {
  background: var(--white);
  color: var(--charcoal);
}

.profile__icon:target {
  background: var(--athens-gray);
  color: var(--charcoal);
}

.navbar {
  z-index: 202;
  background: transparent;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: none;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

@media (min-width: 1024px) {
  .navbar {
    display: block;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(30%, var(--dark-gray)),
      color-stop(80%, transparent)
    );
    background: linear-gradient(180deg, var(--dark-gray) 30%, transparent 80%);
  }
}

.navbar__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 0;
  height: 100px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0.1)),
    color-stop(rgba(0, 0, 0, 0.1)),
    color-stop(rgba(0, 0, 0, 0.1)),
    color-stop(rgba(0, 0, 0, 0.1)),
    to(transparent)
  );
  background: linear-gradient(
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1),
    transparent
  );
}

@media (min-width: 1024px) {
  .navbar__wrapper {
    padding: 0 15px 0 53px;
  }
}

.navbar__ine-logo {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  max-height: 35px;
}

.navbar__menu-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  color: var(--athens-gray);
  font-family: var(--font-book);
  font-size: 0.875rem;
  padding: 19.5px 0 20.5px 0;
  white-space: nowrap;
  line-height: 20px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .navbar__menu-btn {
    margin: 0 15px;
  }
}

.navbar__menu-btn:hover {
  border-bottom: 3px solid var(--orange);
  color: var(--white);
}

.navbar__menu-btn:active {
  border-bottom: 3px solid var(--white);
  color: var(--white);
}

.navbar__menu-btn > svg {
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
}

.navbar__menu-btn > svg {
  width: 16px;
  margin-left: 5px;
}

.navbar__user-profile {
  margin: 0 0 0 20px;
}

.navbar__menu {
  margin: 0 auto 0 24px;
}

.navbar__mega-menu-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 3px 0 0 0;
}

.navbar__menu-link {
  font-family: var(--font-book);
  color: var(--athens-gray);
  font-size: 0.875rem;
  white-space: nowrap;
  margin: 0;
  padding: 19.5px 0 20.5px 0;
  line-height: 20px;
  border-bottom: 3px solid transparent;
}

@media (min-width: 1024px) {
  .navbar__menu-link {
    margin: 0 15px;
  }
}

.navbar__menu-link:hover {
  cursor: pointer;
  border-bottom: 3px solid var(--orange);
  color: var(--white);
}

.navbar__menu-link:active {
  border-bottom: 3px solid var(--white);
}

.navbar__search-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.navbar__search-icon {
  width: 100%;
  margin-left: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: var(--white);
  padding: 10px;
  height: 44px;
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .navbar__search-icon {
    width: auto;
    margin-left: 10px;
  }
}

.navbar__search-icon:active,
.navbar__search-icon:hover {
  background: var(--white);
  color: var(--charcoal);
}

.navbar__search-icon:target {
  background: var(--athens-gray);
  color: var(--charcoal);
}

.mobile-menu {
  position: absolute;
  width: calc(100% - 18px);
  max-width: 450px;
  top: 68px;
  display: block;
  margin: 0 9px;
  left: 0;
  padding: 0;
  background: var(--glass-mid-gray);
  border-radius: 10px;
}

@media (min-width: 1024px) {
  .mobile-menu {
    display: none;
  }
}

.mobile-menu__menu-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.mobile-menu__link-wrapper {
  position: relative;
  max-height: 50px;
  font-size: 0.875rem;
  color: var(--white);
  border-bottom: 1px solid var(--tundora-gray);
  padding: 15px 20px;
  cursor: pointer;
}

.mobile-menu__link-wrapper:nth-child(2) {
  border-radius: 10px 10px 0 0;
}

.mobile-menu__link-wrapper:hover {
  background: var(--glass-extra-light);
}

.mobile-menu__link-wrapper:hover .mobile-menu__categories-arrow {
  display: block;
}

.mobile-menu__link-wrapper:last-child {
  border-radius: 0 0 10px 10px;
  border: none;
}

.mobile-menu__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: var(--white);
}

.mobile-menu__categories-arrow {
  display: none;
  width: 15px;
  height: 20px;
}

.mobile-menu__arrow {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-right: 6px solid transparent;
  border-top: 6px solid transparent;
  border-left: 6px solid transparent;
  border-bottom: 6px solid var(--dark-gray);
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -11px;
}

.mobile-menu:before {
  position: absolute;
  content: "";
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background: var(--menu-background);
}

.mobile-navbar {
  max-width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 63px;
  background-color: var(--dark-gray);
  padding: 9px 10px;
  background-color: var(--black);
  z-index: 202;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 1024px) {
  .mobile-navbar {
    display: none;
  }
}

.mobile-navbar__right-section {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.mobile-navbar__right-section,
.mobile-navbar__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.mobile-navbar__wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.mobile-navbar__user-profile {
  margin-left: 0;
}

.mobile-navbar__switch-search-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.mobile-navbar__logo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mobile-navbar__ine-logo {
  width: 76.23px;
}

.mobile-navbar__open-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  width: 16px;
  color: var(--white);
  margin-left: 20px;
}

.mobile-navbar__open-menu svg {
  width: 16px;
}

.mobile-navbar__open-menu svg {
  -webkit-transition: -webkit-transform 0.1s ease-out;
  transition: -webkit-transform 0.1s ease-out;
  transition: transform 0.1s ease-out;
  transition: transform 0.1s ease-out, -webkit-transform 0.1s ease-out;
}

@media only screen and (max-width: 361px) {
  .mobile-navbar__open-menu {
    margin-left: 5px;
  }
}

.mobile-navbar__icon {
  width: auto;
  margin-left: auto;
  margin-right: 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  color: var(--white);
  padding: 10px;
  height: 44px;
  border-radius: 5px;
  cursor: pointer;
}

.mobile-navbar__icon:active,
.mobile-navbar__icon:hover {
  background: var(--white);
  color: var(--charcoal);
}

.mobile-navbar__icon:target {
  background: var(--athens-gray);
  color: var(--charcoal);
}

@media only screen and (max-width: 361px) {
  .mobile-navbar__icon {
    padding: 7px;
    height: 28px;
  }
}

.footer {
  /* font-family: var(--font-medium); */
  font-style: normal;
  font-weight: 400;
  color: var(--white);
}

.footer__bottom,
.footer__top {
  max-width: 1440px;
  margin: auto;
  padding: 50px 20px;
}

.footer__bottom-wrapper,
.footer__top-wrapper {
  background: var(--glass-mid-gray);
}

.footer__top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 1024px) {
  .footer__top {
    grid-template-columns: repeat(5, 1fr);
    padding: 50px 24px;
  }
}

@media (min-width: 1300px) {
  .footer__top {
    padding: 50px 100px;
    grid-template-columns: repeat(6, 1fr);
  }
}

.footer__bottom {
  padding-bottom: 40px;
}

@media (min-width: 1024px) {
  .footer__bottom {
    padding: 40px 24px 24px;
  }
}

@media (min-width: 1300px) {
  .footer__bottom {
    display: grid;
    grid-template-columns: 57% 23% 20%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 40px 100px 24px;
  }
}

.footer__links-list {
  margin-bottom: 20px;
}

.footer__links-list:first-of-type {
  grid-column: 1/2;
  grid-row: 2/4;
}

@media (min-width: 1024px) {
  .footer__links-list:first-of-type {
    grid-column: 1/2;
    grid-row: 1;
  }
}

@media (min-width: 1300px) {
  .footer__links-list:first-of-type {
    grid-column: 1/2;
    grid-row: 1;
  }
}

.footer__links-list:nth-of-type(2) {
  grid-column: 2/3;
  grid-row: 2/3;
}

@media (min-width: 1024px) {
  .footer__links-list:nth-of-type(2) {
    grid-column: 2/3;
    grid-row: 1;
  }
}

@media (min-width: 1300px) {
  .footer__links-list:nth-of-type(2) {
    grid-column: 2/3;
    grid-row: 1;
  }
}

.footer__links-list:nth-of-type(3) {
  grid-column: 2/3;
  grid-row: 3/4;
}

@media (min-width: 1024px) {
  .footer__links-list:nth-of-type(3) {
    grid-column: 3/4;
    grid-row: 1;
  }
}

@media (min-width: 1300px) {
  .footer__links-list:nth-of-type(3) {
    grid-column: 3/4;
    grid-row: 1;
  }
}

.footer__links-list-title {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  color: var(--gray-storm);
}

.footer__links-list-item {
  font-size: 14px;
  line-height: 20px;
  padding: 4px 0;
}

.footer__links-list-item a {
  -webkit-transition: padding 0.15s ease;
  transition: padding 0.15s ease;
  color: var(--white);
}

.footer__links-list-item a:hover {
  padding-left: 5px;
}

.footer__links-list-item a:visited {
  color: var(--white);
}

.footer__community-section {
  grid-column: 1/7;
  grid-row: 1/2;
}

@media (min-width: 1024px) {
  .footer__community-section {
    grid-column: 4/6;
    grid-row: 1;
  }
}

@media (min-width: 1300px) {
  .footer__community-section {
    grid-column: 5/7;
    grid-row: 1;
  }
}

.footer__community-title {
  font-size: 12px;
  line-height: 14px;
  color: var(--chetwode-blue);
  margin-bottom: 15px;
}

.footer__community-text {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 30px;
}

@media (min-width: 1024px) {
  .footer__community-text {
    width: 95%;
  }
}

.footer__community-button-cta {
  height: 36px;
  font-size: 14px;
  line-height: 20px;
  color: var(--charcoal);
  padding: 8px 16px;
  margin-bottom: 30px;
}

.footer__all-rights-reserved {
  font-size: 12px;
  line-height: 14px;
}

@media (min-width: 1024px) {
  .footer__all-rights-reserved {
    text-align: center;
  }
}

.footer__links-policies {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 45px 0;
}

@media (min-width: 1300px) {
  .footer__links-policies {
    padding: unset;
    grid-template-columns: repeat(2, 140px);
  }
}

.footer__links-policies-item {
  font-size: 14px;
  line-height: 20px;
  padding: 0 30px;
}

@media (min-width: 1300px) {
  .footer__links-policies-item {
    padding: unset;
    text-align: center;
  }
}

.footer__links-policies-item:first-of-type {
  width: 100%;
  border-right: 1px var(--gray-storm) solid;
  text-align: right;
  padding-right: 30px;
}

@media (min-width: 1300px) {
  .footer__links-policies-item:first-of-type {
    width: unset;
    border-right: unset;
    padding: unset;
    text-align: center;
  }
}

.footer__links-policies-item:link,
.footer__links-policies-item:visited {
  color: var(--white);
}

.footer__links-policies-item:hover {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
}

.footer__links-social {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 0 67.5px;
  max-width: 380px;
  margin: auto;
}

@media (min-width: 1024px) {
  .footer__links-social {
    padding: unset;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    grid-template-columns: repeat(4, 52px) 42px;
  }
}

@media (min-width: 1300px) {
  .footer__links-social {
    padding: unset;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    grid-template-columns: repeat(4, 52px) 42px;
  }
}

.footer__links-social-item {
  justify-self: center;
  width: 32px;
  height: 32px;
  background: var(--glass-light-gray);
  border-radius: 50%;
  -webkit-transition: background 0.15s ease;
  transition: background 0.15s ease;
}

.footer__links-social-item svg path {
  -webkit-transition: fill 0.15s ease;
  transition: fill 0.15s ease;
}

@media (min-width: 1300px) {
  .footer__links-social-item {
    margin: 0 10px;
  }
}

@media (min-width: 1300px) {
  .footer__links-social-item:last-child {
    margin: unset;
    margin-left: 10px;
  }
}

.footer__links-social-item:hover {
  background: var(--white);
}

.footer__links-social-item:hover svg path {
  fill: var(--black);
}

.footer__links-social-item--youtube {
  margin: 10px 8px;
}

.footer__links-social-item--instagram {
  margin: 7px;
}

.footer__links-social-item--facebook,
.footer__links-social-item--linkedin,
.footer__links-social-item--twitter {
  margin: 8px;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

main {
  display: block;
}

a {
  background-color: transparent;
}

img {
  border-style: none;
}

button,
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button {
  text-transform: none;
}

[type="button"],
button {
  -webkit-appearance: button;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

:root {
  --orange: #ef7d22;
  --gray: grey;
  --bright-gray: #aaa;
  --light-gray: #262626;
  --light-gray-darker: #57575c;
  --light-elevation-200: rgba(55, 65, 81, 0.0784313725490196);
  --dark-gray: #151515;
  --darker-gray: #222;
  --business-gray: #aeaeb2;
  --gallery: #eee;
  --shark: #2a282a;
  --mulled-wine-50: rgba(75, 72, 103, 0.5019607843137255);
  --primary-ine-gradient: linear-gradient(
    92.23deg,
    #ef7d22 3.65%,
    #ef741d 25.47%,
    #ee5a10 62.46%,
    #ee3b00 98.5%
  );
  --glass-mid-gray: linear-gradient(
    104.99deg,
    rgba(62, 63, 73, 0.7) -13.91%,
    rgba(62, 63, 73, 0) 100.48%
  );
  --glass-mid-gray-light: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  --glass-solid-mid-gray: linear-gradient(89.99deg, #777 0.5%, #2e2e2e 97.14%);
  --glass-light-gray: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.7) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  --glass-extra-light: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
  --glass-dark: linear-gradient(
    111.02deg,
    rgba(18, 18, 18, 0.7) 3.86%,
    rgba(18, 18, 18, 0) 107.96%
  );
  --glass-purple: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
  --glass-red: linear-gradient(180deg, #c31432 3.12%, rgba(195, 20, 50, 0));
  --glass-pink-violet: linear-gradient(96.51deg, #cf3ad2 7.05%, #5936e4 93.87%);
  --highlight-gray: #2e2e2e;
  --lighter-gray: #717171;
  --white: #fff;
  --white-10: hsla(0, 0%, 100%, 0.10196078431372549);
  --white-20: hsla(0, 0%, 100%, 0.2);
  --white-25: hsla(0, 0%, 100%, 0.25098039215686274);
  --flat-black: #333;
  --black: #000;
  --black-50: rgba(0, 0, 0, 0.5019607843137255);
  --black-footer: #010101;
  --little-black: #292929;
  --red: red;
  --error-background: 20, 26, 32, 0.9;
  --notification-box-shadow: 0px 0px 16px rgba(5, 73, 152, 0.38823529411764707);
  --green: #00dc9b;
  --yellow: #eeecb4;
  --dark-yellow: #edbd02;
  --border-gray: #eaeaea;
  --border-dark-gray: #acacac;
  --purple: #b53dc5;
  --secondary-promo-purple: linear-gradient(89.99deg, #863ad2 3.58%, #5936e4 97.14%);
  --blue-gem-gradient: linear-gradient(358.73deg, rgba(61, 36, 165, 0) 13.2%, #3d24a5 99.82%);
  --blue: #2c6ed0;
  --lochmara: #0073cb;
  --menu-background: #1d1d1d;
  --menu-btn: #333;
  --menu-detail: #989898;
  --menu-subcategory: #777;
  --menu-gradient: linear-gradient(180deg, #111, rgba(17, 17, 17, 0));
  --manage-form-input: #272727;
  --manage-form-placeholder: #aaa;
  --lab-status-green: #22d895;
  --lab-status-yellow: #ffde29;
  --lab-status-red: #fd352e;
  --tango: #ef7d23;
  --highlighter: #ffc504;
  --highlighted: #f83333;
  --metric-gradient: radial-gradient(closest-side at 50% 50%, #215bc7 0%, rgba(33, 19, 177, 0) 100%)
    0% 0% no-repeat padding-box;
  --cert-gray: #252525;
  --charcoal: #1e1e1e;
  --free-alt-bg: #f7f8fa;
  --off-white: #f7f8f9;
  --error: red;
  --detail: #acb2bf;
  --input-background: #111720;
  --input-border: #29285a;
  --cart-background: 20, 26, 32, 0.9;
  --cod-gray: #1c1c1c;
  --dove-gray: #666;
  --silver-gray: #8b8b8b;
  --emperor-gray: #555;
  --payment-border: #202245;
  --payment-border-selected: #3f4388;
  --payment-box-shadow: 0px 0px 16px rgba(5, 73, 152, 0.38823529411764707);
  --checkbox-border: #30336a;
  --tundora-gray: #444;
  --silver-chalice: #b1b1b1;
  --light-blue-gray: #afb0bf;
  --perfect-gray: #888;
  --athens-gray: #e9e9ed;
  --positive-light: #70ffd4;
  --cornflower-blue: #88a9ff;
  --silver-chalice-2: #aaa;
  --mid-gray: #bbbcc7;
  --mid-gray-dark: #797b8c;
  --mid-gray-dark-25: rgba(121, 123, 140, 0.25098039215686274);
  --dark-brighter: #111;
  --aubergine: #221f20;
  --red-gradient: linear-gradient(
    90deg,
    #db4133 8310%,
    #de5936 11665.11%,
    #e27339 15935.25%,
    #e37c3a 18477%
  );
  --gray-lavender: #717071;
  --gray-nine: #999;
  --gray-storm: #797b8c;
  --chetwode-blue: #a793f6;
  --chetwode-blue-50: rgba(167, 147, 246, 0.5019607843137255);
  --orange-gradient: linear-gradient(
    180.18deg,
    #db4133 -5.52%,
    #de5936 36.62%,
    #e27339 90.25%,
    #e37c3a 122.17%
  );
  --oranger-gradient: linear-gradient(
    92.23deg,
    #ef7d22 3.65%,
    #ef741d 25.47%,
    #ee5a10 62.46%,
    #ee3b00 98.5%
  );
  --orangest-horizontal-gradient: linear-gradient(
    90deg,
    #db4133,
    #de5935 33%,
    #e17238 75%,
    #e37c3a
  );
  --orangest-vertical-gradient: linear-gradient(
    180.18deg,
    #db4133 -5.52%,
    #de5935 36.62%,
    #e17238 90.25%,
    #e37c3a 122.17%
  );
  --purple-gradient: linear-gradient(96.51deg, #863ad2 7.05%, #5936e4 93.87%);
  --purple-horizontal-gradient: linear-gradient(270deg, #803995 0.01%, #ea2426 100.01%);
  --purple-vertical-gradient: linear-gradient(0deg, #803995 0.01%, #ea2426 100.01%);
  --purple-pale: #c9bbff;
  --dark-blue: #190da0;
  --caribbean-green: #49e0b3;
  --carnation: #ff5e5e;
  --butterfly-bush: #5b4b9b;
  --shark-gray: #2a2a2f;
  --pale-yellow: #fffec9;
  --light-yellow: #fce57f;
  --performance-light: #f8b369;
  --concrete: #f2f2f2;
  --melrose: #c4b7f9;
  --white-light-box-shadow: 0px 4px 40px hsla(0, 0%, 100%, 0.4);
  --white-medium-box-shadow: 0px 0px 19px hsla(0, 0%, 100%, 0.26);
  --gray-light-box-shadow: 0px 1px 2px rgba(55, 65, 81, 0.08);
  --gray-light-box-shadow-filter: drop-shadow(0px 1px 2px rgba(55, 65, 81, 0.08));
  --carnation-box-shadow: 0px 0px 0px 1px rgba(255, 94, 94, 0.3), inset 0px 0px 0px 1px #ff5e5e;
  --purple-dark-box-shadow: 0px 0px 0px 1px #5b4b9b, inset 0px 0px 0px 1px #a793f6;
  --purple-light: #a793f6;
  --purple-light-box-shadow: 0px 0px 10px rgba(167, 147, 246, 0.5);
  --positive-light-shadow: 0px 0px 10px var(--positive-light);
  --violet-light: #d7cdff;
  --fog: #d3c9fa;
  --larea-networking: #fad740;
  --larea-cloud: #88a9ff;
  --larea-data-science: #66c19a;
  --larea-cyber-security: #bb85ff;
  --larea-it-essentials: #f647c0;
  --larea-development: #d73f47;
  --font-book: "CircularStd-Book";
  --font-bold: "CircularStd-Bold";
  --font-medium: "CircularStd-Medium";
  --font-roboto: "RobotoMono-Regular";
}

* {
  text-decoration: none;
  outline: none !important;
}

html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

body,
html {
  height: 100%;
}

*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

body {
  background-color: var(--dark-gray);
  font-family: var(--font-book);
  min-width: 100px;
  min-height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  font-size: 14px;
  overflow-x: hidden;
  width: 100%;
  direction: ltr;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-left: env(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-right: env(safe-area-inset-right);
  scroll-behavior: smooth;
}

p {
  margin: 0 0 16px;
}

h4 {
  font-weight: 400;
}

h4 {
  font-size: 2.125rem;
  line-height: 2.5rem;
  letter-spacing: 0.00735em;
}

h5 {
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: normal;
}

h5,
h6 {
  line-height: 2rem;
}

h6 {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
}

button,
input {
  overflow: visible;
}

button,
input {
  font: inherit;
  margin: 0;
}

.lead {
  text-transform: uppercase;
  color: var(--gray);
  margin: 0 5px 0 0;
  font-family: var(--font-medium);
}

.data,
.lead {
  padding: 0;
  font-size: 0.875rem;
}

.data {
  color: var(--white);
  margin: 0;
  font-weight: 700;
  font-family: var(--font-bold);
}

@media (min-width: 768px) {
  .data {
    font-size: 1rem;
  }
}

.content-type {
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-size: 1rem;
}

.content-title,
.content-type {
  color: var(--white);
  font-weight: 700;
}

.content-title {
  font-size: 2.25rem;
  letter-spacing: -1.04px;
  line-height: 65px;
  margin: 5px 0 50px 0;
}

@media (min-width: 1800px) {
  .content-title {
    font-size: 3.25rem;
  }
}

.content-description {
  font-size: 1.125rem;
  font-weight: medium;
  overflow: hidden;
  color: #e9e9ed;
  margin: 0;
}

.lab-time-container {
  padding: 7px 14px;
  background: #15151514;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  border: 1px solid #464854;
  margin-bottom: 10px;
  color: var(--white);
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 12px;
}

.btn {
  --btn-height: 48px;
  --btn-side-pad: 24px;
  --btn-border-radius: 8px;
  --btn-bg-color: var(--white, #fff);
  --btn-active-bg-color: var(--athens-gray);
  --btn-font-size: 1rem;
  --btn-text-color: var(--charcoal, #0c0c0c);
  --btn-border-color: var(--emperor-gray, #555);
  --btn-hover-border-color: var(--white, #fff);
  --btn-secondary-text-color: var(--white, #fff);
  --btn-secondary-active-text-color: var(--charcoal, #0c0c0c);
  --btn-icon-size: 24px;
  --btn-disabled-opacity: 0.2;
  --btn-promo-text-color: var(--glass-purple);
  --btn-promo-active-text-color: var(--charcoal, #0c0c0c);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  height: var(--btn-height, 48px);
  padding: 0 var(--btn-side-pad, 24px);
  border-radius: var(--btn-border-radius, 8px);
  background-color: var(--btn-bg-color, var(--white, #fff));
  border: none;
  font-size: var(--btn-font-size, 1rem);
  color: var(--btn-text-color, var(--charcoal, #0c0c0c));
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  will-change: background-color, border-color;
  cursor: pointer;
  white-space: nowrap;
}

.btn svg {
  width: var(--btn-icon-size, 24px);
  height: var(--btn-icon-size, 24px);
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.btn:not(.btn--default-icon-color) svg {
  overflow: visible;
  fill: var(--btn-text-color) !important;
}

.btn:not(.btn--default-icon-color) svg path {
  fill: inherit !important;
}

.btn.btn--primary {
  --btn-box-shadow-opacity: 0.4;
}

.btn.btn--primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  opacity: 0;
  will-change: opacity;
  -webkit-transition: opacity 0.3s, -webkit-box-shadow 0.3s;
  transition: opacity 0.3s, -webkit-box-shadow 0.3s;
  transition: opacity 0.3s, box-shadow 0.3s;
  transition: opacity 0.3s, box-shadow 0.3s, -webkit-box-shadow 0.3s;
  -webkit-box-shadow: 0 4px 40px rgba(255, 255, 255, var(--btn-box-shadow-opacity));
  box-shadow: 0 4px 40px rgba(255, 255, 255, var(--btn-box-shadow-opacity));
  pointer-events: none;
}

.btn.btn--primary:active:before,
.btn.btn--primary:hover:before {
  opacity: 1;
}

.btn.btn--primary:active {
  --btn-box-shadow-opacity: 0.13;
  --btn-bg-color: var(--btn-active-bg-color) !important;
}

.btn.btn--primary:disabled {
  --btn-text-color: var(--dove-gray, #666);
}

.btn.btn--secondary {
  --btn-bg-color: transparent;
  --btn-text-color: var(--btn-secondary-text-color);
  border: 1px solid var(--btn-border-color);
  margin-left: 10px;
}

.btn.btn--secondary:active,
.btn.btn--secondary:hover {
  --btn-border-color: var(--btn-hover-border-color) !important;
}

.btn.btn--secondary:active {
  --btn-bg-color: var(--btn-border-color) !important;
  --btn-text-color: var(--btn-secondary-active-text-color) !important;
}

.btn:disabled {
  opacity: var(--btn-disabled-opacity);
  pointer-events: none;
}

.level {
  margin-bottom: 10px;
}

.level__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: var(--glass-mid-gray);
  border-radius: 5px;
  padding: 10px 20px 10px 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
}

@media (min-width: 1024px) {
  .level__wrapper {
    padding: 20px 25px;
  }
}

.level__wrapper--enabled:hover {
  background: var(--glass-extra-light);
  -webkit-box-shadow: 0 0 1px hsla(0, 0%, 100%, 0.5);
  box-shadow: 0 0 1px hsla(0, 0%, 100%, 0.5);
}

.level__wrapper--enabled:hover .action {
  background: var(--white);
}

.level__wrapper--enabled:hover .action svg path {
  stroke: var(--charcoal);
}

.level__wrapper--enabled:hover .btn--secondary {
  border: 1px solid #fff;
}

.level__wrapper--enabled:hover .level-row__progress-wrapper {
  background: var(--athens-gray);
}

.level__wrapper--enabled:hover .level-row__progress-bar {
  background: var(--light-blue-gray);
}

.level__wrapper--enabled:hover .level-row__icon-wrapper .tickIcon {
  stroke: #aaa;
}

.level__wrapper--enabled:active .btn--secondary {
  background: #fff;
  color: #000;
}

.level__title-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 20px 0;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  margin: 0 auto 0 0;
}

@media (min-width: 1024px) {
  .level__title-wrapper {
    width: 100%;
    width: 60%;
    -webkit-box-ordinal-group: unset;
    -ms-flex-order: unset;
    order: unset;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
  }
}

.level__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  color: var(--white);
  font-size: 18px;
  font-weight: 400;
  font-family: var(--font-book);
  margin-left: 10px;
  margin-bottom: 0;
  width: 100%;
  word-break: break-all;
}

.level__title__label {
  font-size: 0.9rem;
  color: var(--light-blue-gray);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.level__title__label-detail {
  font-weight: 450;
  font-size: 12px;
  line-height: 14px;
}

.level__details-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (min-width: 1024px) {
  .level__details-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: unset;
    flex-flow: unset;
  }
}

.level__details-wrapper .action__trigger {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

@media (min-width: 1024px) {
  .level__details-wrapper .action__trigger {
    -webkit-box-ordinal-group: unset;
    -ms-flex-order: unset;
    order: unset;
  }
}

.level__resume {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
  margin: auto 0 0 10px;
}

@media (min-width: 1300px) {
  .level__resume {
    margin: 0 0 0 25px;
    -webkit-box-ordinal-group: unset;
    -ms-flex-order: unset;
    order: unset;
  }
}

.modal-open {
  overflow-y: hidden !important;
}

.modal-open header {
  display: none !important;
}

.modal-open footer {
  display: none;
}

.flat-scroll {
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  scrollbar-color: hsla(0, 0%, 49.8%, 0.25) hsla(0, 0%, 49.8%, 0.05);
}

.flat-scroll ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}

.flat-scroll ::-webkit-scrollbar-corner,
.flat-scroll ::-webkit-scrollbar-track {
  background: hsla(0, 0%, 49.8%, 0.05);
}

.flat-scroll ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 49.8%, 0.25);
  background-clip: content-box;
  border: 4px solid hsla(0, 0%, 49.8%, 0);
  border-radius: 12px;
}

.flat-scroll ::-webkit-scrollbar-thumb:active,
.flat-scroll ::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 49.8%, 0.4);
}

:root {
  --vs-controls-color: transparent !important;
  --vs-border-color: var(--emperor-gray) !important;
  --vs-dropdown-bg: var(--glass-mid-gray) !important;
  --vs-dropdown-color: var(--gray-nine) !important;
  --vs-dropdown-option-color: var(--gray-nine) !important;
  --vs-selected-bg: var(--light-blue-gray) !important;
  --vs-selected-color: var(--gray-nine) !important;
  --vs-search-input-color: var(--gray-nine) !important;
  --vs-dropdown-option--active-bg: var(--glass-mid-gray) !important;
  --vs-dropdown-option--active-color: var(--white) !important;
  --vs-dropdown-option-padding: 10px 20px !important;
  --vs-dropdown-menu-padding: 10px 20px !important;
}

:root .vs__open-indicator {
  margin-right: 8px;
}

:root .vue-select .vs__dropdown-toggle,
:root .vue-select .vs__search::placeholder {
  background-color: var(--darker-gray);
  border-color: var(--flat-black);
  color: var(--light-blue-gray);
  font-family: var(--font-regular);
}

:root .vue-select .vs__selected-options {
  margin-left: 4px;
}

:root .vue-select .vs__dropdown-toggle {
  height: 46px;
  font-size: 0.75rem;
  font-family: var(--font-book);
  padding-left: 4px;
  border: 1px solid var(--emperor-gray);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: var(--gray-light-box-shadow);
  box-shadow: var(--gray-light-box-shadow);
}

:root .vue-select .vs__dropdown-toggle:focus {
  border-color: var(--flat-black);
  -webkit-box-shadow: var(--purple-dark-box-shadow);
  box-shadow: var(--purple-dark-box-shadow);
}

:root .vue-select .vs__dropdown-toggle:hover {
  border-color: var(--perfect-gray);
}

.container {
  min-height: 100vh;
}

/*! CSS Used from: Embedded */
.cc-color-override-753489847.cc-window {
  color: rgb(255, 255, 255);
  background-color: rgb(20, 26, 32);
}

.cc-color-override-753489847 .cc-link,
.cc-color-override-753489847 .cc-link:active,
.cc-color-override-753489847 .cc-link:visited {
  color: rgb(255, 255, 255);
}

.cc-color-override-753489847 .cc-btn {
  color: rgb(255, 255, 255);
  border-color: transparent;
  background-color: rgb(239, 125, 34);
}

.cc-color-override-753489847 .cc-btn:hover,
.cc-color-override-753489847 .cc-btn:focus {
  background-color: rgb(255, 163, 72);
}

/*! CSS Used from: https://my.ine.com/css/chunk-25843728.6cb88ffa.css */
.files {
  padding: 35px 35px;
  width: 100%;
}

.files--courses {
  padding: 0;
  font-weight: 700;
  font-size: 14px;
  padding-top: 3px;
}

.progress-bar {
  background-color: transparent;
  border-radius: 0;
  padding: 3px;
  width: 100%;
}

.progress-bar__wrapper {
  border-radius: 5px;
  padding: 3px;
  background-color: rgba(51, 51, 51, 0.96);
  text-align: center;
}

.progress-bar__bar {
  background-color: var(--green);
  height: 5px;
  border-radius: 2px;
  position: relative;
}

.progress-bar__bar:after {
  position: absolute;
  right: -13px;
  top: -5px;
  content: "";
  display: block;
  background-color: var(--green);
  height: 15px;
  width: 15px;
  border-radius: 100%;
}

@media (min-width: 768px) {
  .progress-bar__bar:after {
    top: -11px;
    right: -26px;
    height: 26px;
    width: 26px;
  }
}

.radial-progress-container {
  position: relative;
}

.radial-progress-inner {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  border-radius: 50%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/*! CSS Used from: https://my.ine.com/css/chunk-7c9e2914.6d0953a8.css */
.action__trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: none;
  border-radius: 5px;
  background: var(--glass-mid-gray);
  height: 44px;
  width: 44px;
  padding: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.action__trigger:hover {
  background: var(--white);
}

.feedback-btn {
  --btn-border-color: hsla(0, 0%, 100%, 0.7);
}

.overview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0 0 20px 0;
}

@media (min-width: 1024px) {
  .overview {
    padding: 0 0 75px 0;
  }
}

.overview__course-info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
  padding: 0;
}

@media (min-width: 1024px) {
  .overview__course-info-wrapper {
    width: 40%;
    padding: 0 25px 0 0;
  }
}

@media (min-width: 1300px) {
  .overview__course-info-wrapper {
    padding: 0 75px 0 0;
  }
}

@media (min-width: 1800px) {
  .overview__course-info-wrapper {
    padding: 0 100px 0 0;
  }
}

.overview__branding-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.overview__branding {
  margin: 0;
  font-family: var(--font-medium);
}

.overview__title {
  margin: 10px 0 30px 0;
  font-family: var(--font-bold);
  line-height: normal;
  letter-spacing: normal;
  font-weight: 100;
}

@media (min-width: 768px) {
  .overview__title {
    letter-spacing: -1.04px;
    line-height: 2.7rem;
  }
}

.overview__description-wrapper {
  margin: 0 0 50px 0;
}

.overview__description {
  font-size: 0.875rem;
  line-height: 20px;
  letter-spacing: -0.36px;
  white-space: pre-line;
}

.overview__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.overview__actions .btn--secondary {
  --btn-border-color: hsla(0, 0%, 100%, 0.7);
}

.overview__activator {
  margin: 0 3px 0 0;
}

@media (min-width: 768px) {
  .overview__activator {
    margin: 0 20px 0 0;
    max-width: 125px;
  }
}

.instructor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--dark-gray);
  padding: 0 15px 50px 15px;
  min-height: 117px;
  width: 100%;
}

@media (min-width: 768px) {
  .instructor {
    width: 60%;
  }
}

@media (min-width: 1024px) {
  .instructor {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (min-width: 1300px) {
  .instructor {
    padding: 0 20px 0 50px;
  }
}

.instructor__profile-wrapper {
  width: 110px;
  margin: 0 25px 0 0;
}

.instructor__profile-wrapper:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .instructor__profile-wrapper {
    width: 170px;
  }
}

.instructor__profile {
  border-radius: 100%;
  width: 100px;
  margin: 0 0 0 10px;
}

@media (min-width: 768px) {
  .instructor__profile {
    margin: -50px 0 0 0;
    width: 170px;
    max-height: 170px;
  }
}

.instructor__name {
  padding: 40px 0 0 0;
  margin: 0 50px 0 0;
}

.instructor__full-name {
  color: var(--white);
  margin: 0;
  padding: 0;
  font-weight: 700;
  font-size: 16px;
  font-family: var(--font-bold);
}

@media (min-width: 1300px) {
  .instructor__full-name {
    font-size: 17px;
  }
}

.difficulty__icon {
  fill: var(--white);
}

.difficulty {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.difficulty__label {
  display: none;
  color: var(--white);
  text-transform: capitalize;
  font-family: var(--font-bold);
}

@media (min-width: 1024px) {
  .difficulty__label {
    display: block;
  }
}

.difficulty__levels {
  width: 20px;
  margin-right: 10px;
}

.stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--dark-gray);
  padding: 40px 10px 0 10px;
  min-height: 117px;
  width: 100%;
}

.stats .lead {
  margin: 0;
}

@media (min-width: 1024px) {
  .stats .lead {
    white-space: nowrap;
  }
}

@media (min-width: 1024px) {
  .stats {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media (min-width: 1300px) {
  .stats {
    padding: 40px 50px 0 0;
  }
}

.stats__difficulty,
.stats__duration {
  margin: 0 25px 0 0;
}

.stats__files {
  max-width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.stats__files-wrapper-uuids {
  color: #fff;
}

.details {
  position: relative;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.details__instructor,
.details__stats {
  -webkit-box-flex: 50%;
  -ms-flex: 50% 1 0px;
  flex: 50% 1 0;
}

.level__btn-sub {
  max-width: 120px;
}

@media (min-width: 768px) {
  .level__btn-sub {
    min-width: 151px;
    max-width: unset;
  }
}

.level-row__progress-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-radius: 5px;
  background: var(--glass-light-gray);
}

.level-row__progress-bar {
  background-color: var(--white);
  border-radius: 0 0 5px 5px;
  height: 3px;
  position: relative;
}

.level-row__icon-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 24px;
  line-height: 0;
  -ms-flex-item-align: start;
  align-self: flex-start;
  margin-top: 5px;
}

@media (min-width: 1024px) {
  .level-row__icon-wrapper {
    margin: 0 10px 0 0;
    -ms-flex-item-align: center;
    align-self: center;
  }
}

.level-row__icon-wrapper .tickIcon {
  stroke: var(--emperor-gray);
  strokeWidth: 3;
  strokeLinecap: round;
  strokeLinejoin: round;
}

.level-row__action-icon {
  min-width: 20px;
  min-height: 18px;
  height: -webkit-fill-available;
}

.level-row__action {
  margin-right: auto;
}

@media (min-width: 1024px) {
  .level-row__action {
    margin-right: 0;
  }
}

.action__trigger {
  background: var(--glass-mid-gray);
}

.topic-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  position: relative;
  margin: 20px 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  background-color: var(--glass-mid-gray);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

@media (min-width: 1024px) {
  .topic-progress {
    margin-top: 30px;
  }
}

.topic-progress__progress-count {
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 14px;
  padding: 3px;
  white-space: nowrap;
}

.group__topic {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

@media (min-width: 1024px) {
  .group__topic {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.topic {
  width: 100%;
  background: linear-gradient(
    104.99deg,
    rgba(62, 63, 73, 0.7) -13.91%,
    rgba(62, 63, 73, 0) 100.48%
  );
  border-radius: 5px;
  padding: 30px 23px 30px 30px;
  color: var(--white);
}

@media (min-width: 1024px) {
  .topic {
    width: 25%;
    margin: 0;
    padding: 30px;
    min-height: 144px;
    max-width: 238px;
  }
}

.topic__title {
  font-size: 20px;
  font-weight: 700;
  margin: 0 0 22px 0;
  max-width: 90%;
  font-family: var(--font-book);
}

.topic__detail {
  margin: 0;
  padding: 0;
}

.topic__lead {
  margin: 0 5px 0 0;
  font-size: 16px;
}

.topic__data {
  font-size: 16px;
}

.topic__notes-wrapper {
  width: 100%;
  margin: -10px 0 0 0;
}

.topic__level-wrapper {
  width: 100%;
  padding: 20px 20px 10px 20px;
}

.topic__toggle {
  text-decoration: underline;
  margin-top: auto;
  color: var(--light-blue-gray);
}

.topic__toggle:hover {
  cursor: pointer;
  color: var(--white);
}

.topic__aside {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.group__title {
  font-size: 2rem;
  color: var(--white);
  margin-bottom: 30px;
  font-family: var(--font-regular);
}

.group__topic-wrapper {
  background: linear-gradient(
    104.99deg,
    rgba(62, 63, 73, 0.7) -13.91%,
    rgba(62, 63, 73, 0) 100.48%
  );
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--light-gray);
  border-radius: 75px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 25px;
}

@media (min-width: 1024px) {
  .progress {
    padding: 25px 50px;
  }
}

.progress__progress {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: none;
}

@media (min-width: 768px) {
  .progress__progress {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.progress__progress-icon {
  fill: var(--green);
  height: 58px;
  margin-right: 27px;
}

.progress__progress-title {
  color: var(--white);
  font-size: 22px;
  margin: 0 30px 0 0;
  width: 100%;
  font-family: var(--font-bold);
  line-height: 1.5rem;
  letter-spacing: -0.44px;
}

.progress__bar-wrapper {
  width: 100%;
  margin-right: 45px;
}

.course {
  /* background-color: var(--dark-gray); */
  /* padding: 0 0 10% 0; */
  margin: 0 auto;
  /* height: 100vh; */
}

.course__background {
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  padding: 60px 0 0 0;
}

.course__overview-wrapper {
  max-width: 1900px;
  padding: 0 15px 0px 15px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .course__overview-wrapper {
    padding: 0 100px 0px 100px;
  }
}

.course__details-wrapper {
  margin: 0 auto;
  padding: 0;
  max-width: 1900px;
}

@media (min-width: 1300px) {
  .course__details-wrapper {
    padding: 0 100px;
  }
}

.course__content-wrapper {
  /* background-color: var(--dark-gray); */
  max-width: 1900px;
  padding: 0 5px;
  margin: 0 auto;
}

@media (min-width: 1024px) {
  .course__content-wrapper {
    padding: 0px 40px 100px 40px;
  }
}

@media (min-width: 1300px) {
  .course__content-wrapper {
    padding: 0px 100px 120px 100px;
  }
}

.course__group-wrapper {
  position: relative;
  padding-top: 45px;
}

@media (min-width: 768px) {
  .course__group-wrapper {
    padding-top: 0;
  }
}

.course__group-dropdown-title {
  display: none;
  font-size: 1.25rem;
  line-height: 25px;
  color: var(--white);
  margin: 0 0 10px 0;
  font-family: var(--font-regular);
}

@media (min-width: 768px) {
  .course__group-dropdown-title {
    font-size: 2rem;
    line-height: 46px;
    margin: 0;
  }
}

.course__group-dropdown {
  position: absolute;
  width: 100%;
  right: 0;
  top: 0;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 100;
}

@media (min-width: 768px) {
  .course__group-dropdown {
    height: 63px;
    top: -15px;
  }
}

.course__group-dropdown-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  width: 100%;
}

@media (min-width: 768px) {
  .course__group-dropdown-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.course__options-wrapper {
  width: 100%;
  position: relative;
  height: 46px;
}

@media (min-width: 768px) {
  .course__options-wrapper {
    width: 306px;
  }
}

.course__progress-wrapper {
  width: 95%;
  margin: 0 auto;
}

/*! CSS Used from: https://my.ine.com/css/chunk-2ddd94da.caa47ca6.css */
.status-icon__circle {
  width: 12.94px;
  height: 13px;
  background: var(--tundora-gray);
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  display: block;
  border-radius: 50%;
}

.keyboard-selection {
  margin-bottom: 35px;
  position: relative;
  font-size: 0.75rem;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.keyboard-selection,
.keyboard-selection__selected {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.keyboard-selection__selected {
  cursor: pointer;
  color: var(--white);
  margin-left: 10px;
}

.keyboard-selection__selected-text {
  border-bottom: 1px solid var(--white);
}

.keyboard-selection__flag {
  font-size: 1.3125rem;
  line-height: 0;
}

.pta-card-session {
  max-width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.pta-card-session,
.pta-card-session__body {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.pta-card-session__body {
  text-align: left;
  padding-bottom: 40px;
  -ms-flex-line-pack: start;
  align-content: flex-start;
}

.pta-card-session__title {
  font-size: 18px;
  line-height: 24px;
  color: var(--white);
  position: relative;
  text-transform: capitalize;
  margin: 0 0 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.pta-card-session__title-wrapper {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pta-card-session__title-group {
  width: 100%;
}

.pta-card-session__subtitle {
  margin: 0;
  margin-right: 10px;
}

@media (min-width: 1024px) {
  .pta-card-session__subtitle {
    max-width: 80%;
    margin-right: 0;
  }
}

.pta-card-session__status-icon {
  margin-left: 10px;
}

.pta-card-session__btn {
  margin-left: auto;
  margin-top: auto;
}

.pta-card-session__buttons {
  width: 100%;
  text-align: right;
}

.pta-card-session__region-selector__wrapper {
  margin-bottom: 15px;
}

.pta-card-session__region-info-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--white);
  margin-bottom: 10px;
  font-size: 13px;
}
.pta-card-session__help-text {
  font-size: 14px;
}
.pta-card-session__region-info-icon {
  width: 14.7px;
  cursor: pointer;
  margin-right: 5px;
}

.pta-card-session__action-buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.pta-card-session__actions-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  height: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-between; */
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@media (min-width: 1024px) {
  .pta-card-session__actions-wrapper {
    max-width: 60%;
  }
}

.pta-card-session__region-selector {
  min-width: 220px;
  max-width: 300px;
  margin-bottom: 10px;
}

.pta-card-session__region-selector__wrapper {
  position: relative;
}

/*! CSS Used from: https://my.ine.com/css/chunk-2e2d240a.ea17c0b0.css */
.title-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px 30px 20px;
  z-index: 204;
  background: var(--dark-gray);
  border-top: 5px solid var(--orange);
  border-bottom: 1px solid #424040;
  max-height: 100px;
}

.title-bar__ine-logo {
  margin-right: 38px;
}

.title-bar__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.title-bar__content-loaded {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.title-bar__title-wrapper {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.title-bar__title {
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1rem;
  display: none;
  color: #fff;
  margin-left: 15px;
}

@media (min-width: 768px) {
  .title-bar__title {
    display: block;
  }
}

@media (min-width: 1300px) {
  .title-bar__title {
    font-size: 18px;
    line-height: 2.5rem;
    margin-top: 3px;
  }
}

.title-bar__close-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 20px;
}

.title-bar__close-modal-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.title-bar__close-modal-btn:hover {
  opacity: 0.5;
}

.title-bar__close-icon {
  margin: 8px 0 0 0;
  color: var(--black);
}

.title-bar__status {
  margin-left: auto;
  color: var(--white);
  text-transform: uppercase;
  white-space: nowrap;
  -ms-flex-line-pack: center;
  align-content: center;
  font-size: 12px;
}

.title-bar__status,
.title-bar__status__pill {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.title-bar__status__pill {
  padding: 9px 14px;
  background: #151515;
  border-radius: 20px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 2px solid #2b2c31;
  margin-left: 10px;
}

.title-bar__report-issue {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 30px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.title-bar__report-issue__icon {
  margin-right: 5px;
  fill: #fff;
}

.session-lab-card {
  width: 100%;
}

.session-lab-card__wrapper {
  color: var(--light-blue-gray);
  min-height: 160px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: linear-gradient(
    104.99deg,
    rgba(62, 63, 73, 0.7) -13.91%,
    rgba(62, 63, 73, 0) 100.48%
  );
  border-radius: 10px;
  padding: 30px;
  position: relative;
}

.session-lab-card__body {
  width: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: inline-block;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .session-lab-card__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.lab-modal__modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--dark-gray);
  z-index: 205;
  overflow-y: auto;
}

.lab-modal__wrapper {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  z-index: 206;
  position: relative;
}

.lab-modal__content-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 2800px;
  height: 100%;
}

.lab-modal__left {
  padding: 30px 40px;
  background: linear-gradient(
    112.2deg,
    rgba(187, 188, 199, 0.4) -46.39%,
    rgba(187, 188, 199, 0) 112.38%
  );
}

.lab-modal__tabs-nav {
  margin-bottom: 30px;
}

.lab-modal__tabs-nav,
.lab-modal__tabs-nav-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.lab-modal__tabs-nav-item {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 34px;
  padding: 0 15px;
  border-radius: 40px;
  background: hsla(0, 0%, 100%, 0);
  font-size: 12px;
  line-height: 14px;
  text-transform: capitalize;
  color: var(--light-blue-gray);
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
}

.lab-modal__tabs-nav-item:not(:last-child) {
  margin-right: 20px;
}

.lab-modal__tabs-nav-item.active,
.lab-modal__tabs-nav-item:hover {
  background: #fff;
  color: var(--charcoal);
}

.lab-modal__description {
  color: #fff;
  font-size: 16px;
}

.lab-modal__description a {
  color: var(--blue);
}

.lab-modal__right {
  padding: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background-image: url(https://my.ine.com/img/dashboard_bg.fcc8d0cd.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.lab-modal__body {
  width: 100%;
}

@media (min-width: 1024px) {
  .lab-modal__body {
    width: 100%;
    height: calc(100% - 100px);
    overflow: hidden;
  }

  .lab-modal__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .lab-modal__left,
  .lab-modal__right {
    width: 50%;
    height: 100%;
    overflow: auto;
  }

  .lab-modal__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.lab-modal__description {
  flex-direction: column;
}

.lab-modal__description,
.lab-modal__description-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  height: 100%;
}

.lab-modal__description-wrapper {
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/*! CSS Used from: Embedded */
/* #MathJax_Message {
    position: fixed;
    left: 1em;
    bottom: 1.5em;
    background-color: #E6E6E6;
    border: 1px solid #959595;
    margin: 0px;
    padding: 2px 8px;
    z-index: 102;
    color: black;
    font-size: 80%;
    width: auto;
    white-space: nowrap;
} */

/*! CSS Used keyframes */
@-webkit-keyframes vSelectSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes vSelectSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

/* region dropdown */

/*! CSS Used from: https://my.ine.com/css/chunk-vendors.8952340f.css */
.v-select * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.vs__dropdown-menu {
  background: var(--vs-dropdown-bg);
  border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
  border-radius: 0 0 var(--vs-border-radius) var(--vs-border-radius);
  border-top-style: none;
  -webkit-box-shadow: var(--vs-dropdown-box-shadow);
  box-shadow: var(--vs-dropdown-box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--vs-dropdown-color);
  display: block;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: var(--vs-dropdown-max-height);
  min-width: var(--vs-dropdown-min-width);
  overflow-y: auto;
  padding: 5px 0;
  position: absolute;
  text-align: left;
  top: calc(100% - var(--vs-border-width));
  width: 100%;
  z-index: var(--vs-dropdown-z-index);
}
.vs__dropdown-option {
  clear: both;
  color: var(--vs-dropdown-option-color);
  cursor: pointer;
  display: block;
  line-height: 1.42857143;
  padding: var(--vs-dropdown-option-padding);
  white-space: nowrap;
}
/*! CSS Used from: https://my.ine.com/css/app.ad2cd678.css */
* {
  text-decoration: none;
  outline: none !important;
}
*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.flat-scroll ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}
.flat-scroll ::-webkit-scrollbar-corner,
.flat-scroll ::-webkit-scrollbar-track {
  background: hsla(0, 0%, 49.8%, 0.05);
}
.flat-scroll ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 49.8%, 0.25);
  background-clip: content-box;
  border: 4px solid hsla(0, 0%, 49.8%, 0);
  border-radius: 12px;
}
.flat-scroll ::-webkit-scrollbar-thumb:active,
.flat-scroll ::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 49.8%, 0.4);
}
.vs__dropdown-menu {
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  scrollbar-color: hsla(0, 0%, 49.8%, 0.25) hsla(0, 0%, 49.8%, 0.05);
}
.vs__dropdown-menu::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}
.vs__dropdown-menu::-webkit-scrollbar-corner,
.vs__dropdown-menu::-webkit-scrollbar-track {
  background: hsla(0, 0%, 49.8%, 0.05);
}
.vs__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 49.8%, 0.25);
  background-clip: content-box;
  border: 4px solid hsla(0, 0%, 49.8%, 0);
  border-radius: 12px;
}
.vs__dropdown-menu::-webkit-scrollbar-thumb:active,
.vs__dropdown-menu::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 49.8%, 0.4);
}
:root .vue-select .vs__dropdown-menu {
  background-color: var(--darker-gray);
  border-color: var(--flat-black);
  color: var(--light-blue-gray);
  font-family: var(--font-regular);
}
:root .vue-select .vs__dropdown-menu {
  position: absolute;
  top: 52px;
  font-size: 0.75rem;
  border-radius: 10px;
  height: auto;
  padding: 0;
}
:root .vue-select .vs__dropdown-menu li {
  height: 40px;
}

.loading-small {
  line-height: 0;
}
.loading-small__spinner {
  -webkit-animation-name: spin-data-v-edf137b6;
  animation-name: spin-data-v-edf137b6;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes spin-data-v-edf137b6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spin-data-v-edf137b6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.status-icon__circle {
  width: 12.94px;
  height: 13px;
  background: var(--tundora-gray);
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
  display: block;
  border-radius: 50%;
}
.status-icon__circle--started {
  background: #00da99;
}

/* solution format */
/*! CSS Used from: https://my.ine.com/css/app.ad2cd678.css */
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
b,
strong {
  font-weight: bolder;
}
code {
  font-family: monospace, monospace;
  font-size: 1em;
}
* {
  text-decoration: none;
  outline: none !important;
}
*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
p {
  margin: 0 0 16px;
}
.flat-scroll ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}
.flat-scroll ::-webkit-scrollbar-corner,
.flat-scroll ::-webkit-scrollbar-track {
  background: hsla(0, 0%, 49.8%, 0.05);
}
.flat-scroll ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 49.8%, 0.25);
  background-clip: content-box;
  border: 4px solid hsla(0, 0%, 49.8%, 0);
  border-radius: 12px;
}
.flat-scroll ::-webkit-scrollbar-thumb:active,
.flat-scroll ::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 49.8%, 0.4);
}
.markdown-styles h2 {
  font-size: 1.5em;
  font-weight: bolder;
}
/*! CSS Used from: https://my.ine.com/css/chunk-2e2d240a.ea17c0b0.css */
.lab-modal__description code {
  color: #fff;
  background: #1e1e1e;
  padding: 5px 7px;
  border-radius: 10px;
  font-size: 0.9em;
  overflow: auto;
  display: block;
}
.lab-modal__description .codehilite code {
  background: #000;
}
.lab-modal__description .codehilite {
  font-size: 0.95em;
  background: #000;
  height: auto;
  display: block;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
  overflow: initial;
}
.lab-modal__description-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  height: 100%;
}
.lab-modal__description-wrapper {
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.lab-modal--remarked {
  background: var(--tango);
  color: var(--shark);
}

/* test tmp */

/*! CSS Used from: https://my.ine.com/css/app.ad2cd678.css */
.lab-modal__description img {
  border-style: none;
  width: 100%;
}
.markdown_solution__text h2 {
  font-weight: 100;
}

.benfits_wrapper {
  padding: 17px;
  font-family: monospace;
  border-radius: 10px;
  background: #151516;
}

.v-select * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.vs__dropdown-menu {
  background: var(--vs-dropdown-bg);
  border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
  border-radius: 0 0 var(--vs-border-radius) var(--vs-border-radius);
  border-top-style: none;
  -webkit-box-shadow: var(--vs-dropdown-box-shadow);
  box-shadow: var(--vs-dropdown-box-shadow);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--vs-dropdown-color);
  display: block;
  left: 0;
  list-style: none;
  margin: 0;
  max-height: var(--vs-dropdown-max-height);
  min-width: var(--vs-dropdown-min-width);
  overflow-y: auto;
  padding: 5px 0;
  position: absolute;
  text-align: left;
  top: calc(100% - var(--vs-border-width));
  width: 100%;
  z-index: var(--vs-dropdown-z-index);
}
.vs__dropdown-option {
  clear: both;
  color: var(--vs-dropdown-option-color);
  cursor: pointer;
  display: block;
  line-height: 1.42857143;
  padding: var(--vs-dropdown-option-padding);
  white-space: nowrap;
}
/*! CSS Used from: https://my.ine.com/css/app.63de3d10.css */
* {
  text-decoration: none;
  outline: none !important;
}
*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
.flat-scroll ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}
.flat-scroll ::-webkit-scrollbar-corner,
.flat-scroll ::-webkit-scrollbar-track {
  background: hsla(0, 0%, 49.8%, 0.05);
}
.flat-scroll ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 49.8%, 0.25);
  background-clip: content-box;
  border: 4px solid hsla(0, 0%, 49.8%, 0);
  border-radius: 12px;
}
.flat-scroll ::-webkit-scrollbar-thumb:active,
.flat-scroll ::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 49.8%, 0.4);
}
.vs__dropdown-menu {
  overflow: auto;
  -webkit-overflow-scrolling: auto;
  scrollbar-color: hsla(0, 0%, 49.8%, 0.25) hsla(0, 0%, 49.8%, 0.05);
}
.vs__dropdown-menu::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}
.vs__dropdown-menu::-webkit-scrollbar-corner,
.vs__dropdown-menu::-webkit-scrollbar-track {
  background: hsla(0, 0%, 49.8%, 0.05);
}
.vs__dropdown-menu::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 49.8%, 0.25);
  background-clip: content-box;
  border: 4px solid hsla(0, 0%, 49.8%, 0);
  border-radius: 12px;
}
.vs__dropdown-menu::-webkit-scrollbar-thumb:active,
.vs__dropdown-menu::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 49.8%, 0.4);
}
:root .vue-select .vs__dropdown-menu {
  background-color: var(--darker-gray);
  border-color: var(--flat-black);
  color: var(--light-blue-gray);
  font-family: var(--font-regular);
}
:root .vue-select .vs__dropdown-menu {
  position: absolute;
  top: 52px;
  font-size: 0.75rem;
  border-radius: 10px;
  height: auto;
  padding: 0;
}
:root .vue-select .vs__dropdown-menu li {
  height: 40px;
}
.lab_run_info {
  padding: 5px;
  border-radius: 6px;
  text-align: center;
  justify-content: center;
  border: 1px solid rgb(187 188 199 / 25%);
  max-width: 200px;
}

/* last imp */
/*! CSS Used from: https://my.ine.com/css/app.a76ca7f5.css */
.btn__switch[data-v-b63abe88] {
  display: inline-block;
  height: 20px;
  position: relative;
  width: 40px;
  padding: 5px;
}
.btn__switch input[data-v-b63abe88] {
  display: none;
}
.btn__switch__slider[data-v-b63abe88] {
  background-color: var(--emperor-gray);
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.btn__switch__slider[data-v-b63abe88]:hover {
  -webkit-box-shadow: 0 4px 40px hsla(0, 0%, 100%, 0.4);
  box-shadow: 0 4px 40px hsla(0, 0%, 100%, 0.4);
}
.btn__switch__slider[data-v-b63abe88]:before {
  background-color: #222;
  bottom: 0;
  content: "";
  height: 18px;
  left: 1px;
  position: absolute;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: 18px;
  -webkit-transform: translateY(-5%);
  transform: translateY(-5%);
}
.btn__switch__slider--round[data-v-b63abe88] {
  border-radius: 34px;
}
.btn__switch__slider--round[data-v-b63abe88]:before {
  border-radius: 50%;
}
input:checked + .btn__switch__slider[data-v-b63abe88] {
  background-color: #70ffd4;
}
input:checked + .btn__switch__slider[data-v-b63abe88]:before {
  -webkit-transform: translate(20px, -5%);
  transform: translate(20px, -5%);
  left: 0;
}
input:disabled + .btn__switch__slider[data-v-b63abe88] {
  opacity: 0.6;
}
input {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
input {
  overflow: visible;
}
[type="checkbox"] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
* {
  text-decoration: none;
  outline: none !important;
}
*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
input {
  overflow: visible;
}
input {
  font: inherit;
  margin: 0;
}
.flat-scroll ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
}
.flat-scroll ::-webkit-scrollbar-corner,
.flat-scroll ::-webkit-scrollbar-track {
  background: hsla(0, 0%, 49.8%, 0.05);
}
.flat-scroll ::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 49.8%, 0.25);
  background-clip: content-box;
  border: 4px solid hsla(0, 0%, 49.8%, 0);
  border-radius: 12px;
}
.flat-scroll ::-webkit-scrollbar-thumb:active,
.flat-scroll ::-webkit-scrollbar-thumb:hover {
  background-color: hsla(0, 0%, 49.8%, 0.4);
}
/*! CSS Used from: https://my.ine.com/css/chunk-6eb6755c.caa47ca6.css */
.pta-card-session__actions[data-v-e773932c] {
    margin-left: auto;
    align-self: center;
    border-bottom: 1px solid #4c4b67;
    margin-bottom: 10px;
}
.pta-card-session__actions__switcher[data-v-e773932c] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.pta-card-session__actions__switcher span[data-v-e773932c] {
  margin-right: 10px;
  display: inline-block;
  white-space: nowrap;
}
.pta-card-session__actions__switcher__text[data-v-e773932c] {
  color: var(--white);
  line-height: 0;
  -webkit-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
