/*! CSS Used from: https://my.ine.com/css/app.ad2cd678.css */
.message {
    position: relative;
    width: 100%;
    margin: 10px;
    padding: 25px 15px;
    background: -webkit-gradient(
      linear,
      right top,
      left top,
      from(var(--bg-color-1, #0a7)),
      to(var(--bg-color-2, #007150))
    );
    background: linear-gradient(
      270deg,
      var(--bg-color-1, #0a7),
      var(--bg-color-2, #007150)
    );
    border-radius: 10px;
    will-change: opacity, transform;
    -webkit-animation: messageAnim 0.5s forwards;
    animation: messageAnim 0.5s forwards;
    cursor: pointer;
  }
  @media (min-width: 768px) {
    .message {
      min-width: 350px;
      max-width: 25vw;
      margin: 0 0 5px 0;
    }
  }
  .message__inner {
    position: relative;
    padding-left: 15px;
    padding-right: 32px;
  }
  .message__icon {
    position: absolute;
    left: 0;
    top: 50%;
    height: 20px;
    width: 20px;
    margin-top: -10px;
  }
  .message__text {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0;
    font-size: 15px;
    line-height: 23px;
    color: #fff;
  }
  .message__close {
    position: absolute;
    right: 0;
    top: 50%;
    width: 12px;
    height: 12px;
    margin-top: -6px;
  }
  .notifications {
    position: fixed;
    /* right: 23px; */
    left: 0;
    z-index: 9999;
    display: flex;
    justify-content: right;
    margin: 20px;
    bottom: 0;
}


/*! CSS Used keyframes */
@-webkit-keyframes messageAnim{0%{opacity:0;-webkit-transform:translateY(30px);transform:translateY(30px);}to{opacity:1;-webkit-transform:translateY(0);transform:translateY(0);}}
@keyframes messageAnim{0%{opacity:0;-webkit-transform:translateY(30px);transform:translateY(30px);}to{opacity:1;-webkit-transform:translateY(0);transform:translateY(0);}}